import { getDefaultStore } from "jotai"
import {
  CancelReservationRequest,
  CancelReservationResponse,
} from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import { authAtom } from "../../atoms"
import { AuthError, ReservationServiceType } from "../services"
import { UseCase } from "./use-case"

export class CancelReservationUseCase
  implements
    UseCase<CancelReservationRequest, Promise<CancelReservationResponse>>
{
  constructor(readonly reservationService: ReservationServiceType) {}

  async call(
    request: CancelReservationRequest
  ): Promise<CancelReservationResponse> {
    const token = getDefaultStore().get(authAtom)
    if (!token) {
      throw new AuthError()
    }

    const result = await this.reservationService.cancelReservation(
      token,
      request
    )
    if (result.isFailure()) {
      console.error(result.value)
      return {}
    }
    return result.value
  }
}
