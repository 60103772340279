import React, { ReactElement } from "react"
import { useMediaQuery } from "react-responsive"
import {
  FeeUnit,
  Parking,
  SubMaxFee,
} from "../../../__generated__/proto/itech/motorist/pksha/v1/parking"
import * as config from "../../../config"
import {
  FeeTableExtension,
  FeeUnitExtension,
  HourlyMaxFeeExtension,
  SubMaxFeeExtension,
} from "../../../domain/parking-extensions"

import * as styles from "./parking-detail-info-row.module.scss"

export const FeesInfoRow = ({
  parking,
}: {
  parking: Parking
}): ReactElement => {
  const isMobile = useMediaQuery({ maxWidth: config.RESPONSIVE_BREAKPOINT })

  const feesMap = (): { [key: string]: string[] } | null => {
    const feeTable = parking.feeTable
    if (!feeTable) {
      return null
    }

    const weekdayFees = feesOfTheDay(
      feeTable.weekdayFees,
      FeeTableExtension.weekdaySubMaxFee(feeTable)
    )
    const saturdayFees = feesOfTheDay(
      feeTable.saturdayFees,
      FeeTableExtension.saturdaySubMaxFee(feeTable)
    )
    const sundayFees = feesOfTheDay(
      feeTable.sundayFees,
      FeeTableExtension.sundaySubMaxFee(feeTable)
    )
    const holidayFees = feesOfTheDay(
      feeTable.holidayFees,
      FeeTableExtension.holidaySubMaxFee(feeTable)
    )

    return {
      平日: weekdayFees,
      土曜: saturdayFees,
      日曜: sundayFees,
      祝日: holidayFees,
    }
  }

  const feesOfTheDay = (
    feeUnits: FeeUnit[],
    subMaxFee: SubMaxFee | undefined
  ): string[] => {
    const fees: string[] = []
    feeUnits.forEach(fu => {
      const startTime = fu.startTime
      const endTime = fu.endTime
      if (!startTime || !endTime) {
        return
      }

      const startHour = startTime.hours.toString().padStart(2, "0")
      const startMinute = startTime.minutes.toString().padStart(2, "0")
      const endHour = endTime.hours.toString().padStart(2, "0")
      const endMinute = endTime.minutes.toString().padStart(2, "0")
      const unitMinutes = FeeUnitExtension.wrappedUnitDurationInMinutes(fu)
      const yen = FeeUnitExtension.wrappedFeePerUnitDuration(fu)
      if (!unitMinutes || !yen) {
        return
      }

      const timeFeeText = `${startHour}:${startMinute}-${endHour}:${endMinute} ${unitMinutes}分/${yen}円`
      let maxFeeText = ""

      if (FeeUnitExtension.isValidFeeUnitMaxFee(fu)) {
        const maxFee = FeeUnitExtension.wrappedMaxFee(fu)
        if (!maxFee) {
          return
        }
        maxFeeText = `時間帯最大料金 ${maxFee}円`
      } else if (
        subMaxFee?.hourly?.hourDuration &&
        subMaxFee?.hourly?.feePerHour
      ) {
        const hours = HourlyMaxFeeExtension.wrappedHourDurationInHours(
          subMaxFee.hourly
        )
        const maxFee = HourlyMaxFeeExtension.wrappedFeePerHour(subMaxFee.hourly)
        if (!hours || !maxFee) {
          return
        }
        maxFeeText = `${hours}時間以内最大料金 ${maxFee}円`
      } else if (subMaxFee?.daily) {
        const maxFee = SubMaxFeeExtension.wrappedDaily(subMaxFee)
        if (!maxFee) {
          return
        }
        maxFeeText = `当日最大料金 ${maxFee}円`
      }

      if (isMobile) {
        fees.push(timeFeeText)
        fees.push(maxFeeText)
      } else {
        fees.push(`${timeFeeText}　${maxFeeText}`)
      }
    })
    return fees
  }

  const feesMapResult = feesMap()
  return (
    <div className={styles.infoRow}>
      <div className={styles.infoKeySection}>料金</div>
      <div className={styles.infoValueSection}>
        <div>
          {feesMapResult &&
            Object.entries(feesMapResult).map(([section, fees]) => {
              return (
                <div key={section} className={styles.feesRow}>
                  <p>{section}</p>
                  <div className={styles.feesText}>
                    {fees.length > 0
                      ? fees.map((fee, index) => <p key={index}>{fee}</p>)
                      : "不明"}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
