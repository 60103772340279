// extracted by mini-css-extract-plugin
export var container = "parking-detail-info-row-module--container--2YviR";
export var infoRow = "parking-detail-info-row-module--infoRow--ED4OX";
export var infoKeySection = "parking-detail-info-row-module--infoKeySection--3WR20";
export var infoValueSection = "parking-detail-info-row-module--infoValueSection--17EiV";
export var addressText = "parking-detail-info-row-module--addressText--3xyeb";
export var feesRow = "parking-detail-info-row-module--feesRow--BJRXA";
export var feesText = "parking-detail-info-row-module--feesText--2svvm";
export var servicesText = "parking-detail-info-row-module--servicesText--1Wa7i";
export var openGoogleMapButton = "parking-detail-info-row-module--openGoogleMapButton--3X7PD";
export var copyAddressButton = "parking-detail-info-row-module--copyAddressButton--iXPrh";
export var downloadQtNetButton = "parking-detail-info-row-module--downloadQtNetButton--3Lviq";