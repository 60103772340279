import { Link } from "gatsby"
import React, { ReactElement } from "react"
import { Region } from "../../domain/use-cases"
import * as styles from "./prefecture-selection-view.module.scss"

interface Props {
  region: Region
}

export const PrefectureSelectionView = (props: Props): ReactElement => {
  const regionSection = (
    region: string,
    prefectures: string[]
  ): ReactElement => (
    <div key={region}>
      <p className={styles.regionText}>{region}</p>
      <div className={styles.prefectureRow}>
        {prefectures.map(prefecture => (
          <Link
            key={prefecture}
            to={`/${prefecture}`}
            className={styles.prefectureLinkText}
          >
            {prefecture}
          </Link>
        ))}
      </div>
    </div>
  )

  return (
    <div className={styles.container}>
      {[...props.region.entries()].map(entry =>
        regionSection(entry[0], entry[1])
      )}
    </div>
  )
}
