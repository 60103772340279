import {
  GoogleMap,
  Marker,
  OverlayView,
  useLoadScript,
} from "@react-google-maps/api"
import React, { ReactElement, useState } from "react"
import { useMediaQuery } from "react-responsive"
import MoonLoader from "react-spinners/MoonLoader"
import { LatLng } from "../../__generated__/proto/google/type/latlng"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import * as config from "../../config"
import * as styles from "./map.module.scss"
import { ParkingPin } from "."

interface Props {
  width: string
  height: string
  center: LatLng | null
  zoom: number
  parkings?: Parking[]
  selectedParking?: Parking
  showCenterMarker: boolean
  onSelectParking?: (parking: Parking) => void
  onCenterChanged: (latLng: LatLng) => void
  onZoomChanged?: (zoom: number) => void
  onBoundsLoaded?: (bounds: google.maps.LatLngBounds) => void
  onBoundsChanged?: (bounds: google.maps.LatLngBounds) => void
}

export const Map = React.memo((props: Props): ReactElement => {
  // MARK: - Variables

  const isMobile = useMediaQuery({ maxWidth: config.RESPONSIVE_BREAKPOINT })

  const centerMarkerSize: google.maps.Size = isMobile
    ? { width: 22, height: 34, equals: () => true }
    : { width: 34, height: 52, equals: () => true }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY || "",
    region: "JP",
    language: "ja",
  })

  // MARK: - States

  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [boundsLoaded, setBoundsLoaded] = useState(false)

  // MARK: - Render

  if (props.center === null) {
    return (
      <div className={styles.placeholderContainer}>
        <MoonLoader color="#262e38" size={32} />
      </div>
    )
  }

  const center: google.maps.LatLngLiteral = {
    lat: props.center.latitude,
    lng: props.center.longitude,
  }

  if (!config.GOOGLE_MAPS_API_KEY || loadError) {
    return (
      <div className={styles.placeholderContainer}>
        <p className={styles.placeholderText}>
          マップを読み込めませんでした <br />
          時間をおいて再度お試しください
          <br />
          {loadError && loadError.message}
        </p>
      </div>
    )
  }

  return config.GOOGLE_MAPS_API_KEY && isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: props.width,
        height: props.height,
      }}
      center={center}
      zoom={props.zoom}
      options={{ disableDefaultUI: true }}
      clickableIcons={false}
      onLoad={setMap}
      onUnmount={setMap}
      onDragEnd={() => {
        const currentCenter = map?.getCenter()
        if (
          currentCenter &&
          currentCenter.lat() !== center.lat &&
          currentCenter.lng() !== center.lng
        ) {
          props.onCenterChanged({
            latitude: currentCenter.lat(),
            longitude: currentCenter.lng(),
          })
        }
      }}
      onZoomChanged={() => {
        const currentZoom = map?.getZoom()
        const currentCenter = map?.getCenter()
        if (props.onZoomChanged && currentZoom && currentZoom !== props.zoom) {
          props.onZoomChanged(currentZoom)
        }
        if (currentCenter) {
          props.onCenterChanged({
            latitude: currentCenter.lat(),
            longitude: currentCenter.lng(),
          })
        }
      }}
      onBoundsChanged={() => {
        const mapBounds = map?.getBounds()
        if (mapBounds && !boundsLoaded && props.onBoundsLoaded) {
          props.onBoundsLoaded(mapBounds)
          setBoundsLoaded(true)
        }
        if (mapBounds && props.onBoundsChanged) {
          props.onBoundsChanged(mapBounds)
        }
      }}
    >
      {props.showCenterMarker && (
        <Marker
          position={center}
          icon={{
            url: "/map_marker.webp",
            scaledSize: centerMarkerSize,
          }}
        />
      )}
      {props.parkings
        ?.filter(parking => parking.name !== props.selectedParking?.name)
        .map(parking => (
          <ParkingOverlayPin
            key={parking.name}
            parking={parking}
            isSelected={false}
            onSelect={parking =>
              props.onSelectParking && props.onSelectParking(parking)
            }
          />
        ))}
      {
        // NOTE: Bring the pin of selected parking to front
        props.selectedParking && (
          <ParkingOverlayPin
            parking={props.selectedParking}
            isSelected={true}
            onSelect={parking =>
              props.onSelectParking && props.onSelectParking(parking)
            }
          />
        )
      }
    </GoogleMap>
  ) : (
    <></>
  )
})

const ParkingOverlayPin = React.memo(
  (props: {
    parking: Parking
    isSelected: boolean
    onSelect: (parking: Parking) => void
  }): ReactElement => {
    const { parking, isSelected, onSelect } = props
    return (
      <OverlayView
        key={parking.name}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={
          parking.latLng && {
            lat: parking.latLng.latitude,
            lng: parking.latLng.longitude,
          }
        }
        getPixelPositionOffset={(w, h) => ({ x: -w / 2, y: -h })}
      >
        <ParkingPin
          parking={parking}
          isSelected={isSelected}
          onSelect={onSelect}
        />
      </OverlayView>
    )
  }
)
