import axios from "axios"
import qs from "qs"
import { LEGACY_API_BASE_URL } from "../../config"
import { User } from "../models"
import { Failure, Result, Success } from "./result"

const PAYMENT_METHODS: { [key: string]: string } = {
  "4": "CREDIT_CARD",
  "5": "WITHDRAWAL",
}

// MARK: - Interfaces
export interface AuthServiceType {
  login(username: string, password: string): Promise<Result<User, AuthError>>
}

// MARK: - Implements
export class AuthService implements AuthServiceType {
  async login(
    username: string,
    password: string
  ): Promise<Result<User, AuthError>> {
    const url = `${LEGACY_API_BASE_URL}/gns/Android_Login`
    const data = qs.stringify({
      login_account: username,
      webpwd: password,
    })

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      if (response.status !== 200) {
        return new Failure(new AuthError())
      }

      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(response.data, "text/xml")
      const root = xmlDoc.getElementsByTagName("Root")[0]
      const result = root.getElementsByTagName("Result")[0]
      const row = result?.getElementsByTagName("Row")[0]
      if (!row) {
        return new Failure(new AuthError())
      }

      const user = new User(
        row.getElementsByTagName("siyosyasei_kanji")[0]?.textContent || "",
        PAYMENT_METHODS[
          row.getElementsByTagName("siharai_keitai")[0]?.textContent || ""
        ] || ""
      )

      return new Success(user)
    } catch (error) {
      console.log(error)
      return new Failure(new AuthError())
    }
  }
}

// MARK: - Errors

export class AuthError extends Error {}
