// extracted by mini-css-extract-plugin
export var header = "mobile-search-header-module--header--2uUEw";
export var upperRow = "mobile-search-header-module--upperRow--1xzAm";
export var commonTextStyle = "mobile-search-header-module--commonTextStyle--1tFvG";
export var userInfo = "mobile-search-header-module--userInfo--3oHkv";
export var serviceName = "mobile-search-header-module--serviceName--11bAX";
export var menu = "mobile-search-header-module--menu--1Ou9u";
export var menuItem = "mobile-search-header-module--menuItem--2Z09V";
export var userSection = "mobile-search-header-module--userSection--1xvL2";
export var dropdownMenu = "mobile-search-header-module--dropdownMenu--3YtNM";
export var dropdownItem = "mobile-search-header-module--dropdownItem--1i-Ln";
export var lowerRow = "mobile-search-header-module--lowerRow--3k5WG";
export var searchInput = "mobile-search-header-module--searchInput--g1w9X";