/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from "gatsby"
import React, { ReactElement } from "react"
import { Helmet } from "react-helmet"

type MetaProps = JSX.IntrinsicElements["meta"]

interface SEOProps {
  title: string
  description?: string
  lang?: string
  meta?: MetaProps[]
}

export const SEO = ({
  description,
  lang,
  meta,
  title,
}: SEOProps): ReactElement => {
  const { site }: GatsbyTypes.SiteQuery = useStaticQuery<GatsbyTypes.SiteQuery>(
    graphql`
      query Site {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site?.siteMetadata?.description
  const metaData: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[] = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    // NOTE: Prevent zooming when a input is focused on iOS
    {
      name: `viewport`,
      content: `width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no`,
    },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang: lang,
      }}
      title={title}
      titleTemplate={`%s | ${site?.siteMetadata?.title}`}
      meta={metaData.concat(meta ?? [])}
    />
  )
}
