import { Link } from "gatsby"
import React, { ReactElement } from "react"
import * as styles from "./town-selection-view.module.scss"

interface Props {
  prefecture: string
  city: string
  towns: string[]
}

export const TownSelectionView = ({
  prefecture,
  city,
  towns,
}: Props): ReactElement => (
  <div className={styles.container}>
    <p className={styles.prefectureCityText}>{prefecture + city}</p>
    <div className={styles.townRow}>
      {towns.map(town => (
        <Link
          key={town}
          to={`/${prefecture}/${city}/${town}`}
          className={styles.townLinkText}
        >
          {town}
        </Link>
      ))}
    </div>
  </div>
)
