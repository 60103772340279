import { navigate } from "gatsby"
import { useAtom } from "jotai"
import React, { useEffect, ReactNode, useCallback } from "react"
import { userAtom, authAtom, parkingNameAtom } from "../atoms"
import { User } from "../domain/models"
import { AuthError, AuthService } from "../domain/services"
import { LoginUseCase } from "../domain/use-cases/login-use-case"

const authService = new AuthService()
const loginUseCase = new LoginUseCase(authService)

type AuthType = {
  user: User | null
  login: (username: string, password: string) => Promise<void>
  logout: () => void
}

export const useAuth = (): AuthType => {
  const [user, setUser] = useAtom(userAtom)
  const [parkingName, setParkingName] = useAtom(parkingNameAtom)
  const [token, setToken] = useAtom(authAtom)
  useEffect(() => {
    if (token && user) {
      setUser(user)
    }
  }, [])

  const login = useCallback(async (username: string, password: string) => {
    const user = await loginUseCase.call({ username, password })
    if (user == null) {
      throw new AuthError("ログインに失敗しました。")
    }
    setToken(window.btoa(`${username}:${password}`))
    setUser(user)

    if (parkingName) {
      navigate(`/${parkingName}/reservation`)
      setParkingName(null)
    } else {
      navigate("/")
    }
  }, [])

  const logout = useCallback(() => {
    setToken(null)
    setUser(null)
  }, [])

  return { user, login, logout }
}

const AuthGuard = ({ children }: { children: ReactNode }): JSX.Element => {
  const [auth] = useAtom(authAtom)

  useEffect(() => {
    if (!auth) {
      navigate("/signin", { replace: true })
    }
  }, [auth])

  if (!auth) {
    return <div>Loading...</div>
  }

  return <>{children}</>
}

export default AuthGuard
