/* eslint-disable */
import Long from "long"
import _m0 from "protobufjs/minimal"
import { Money } from "../../../../google/type/money"
import { Parking } from "../../../../itech/motorist/pksha/v1/parking"
import { Timestamp } from "../../../../google/protobuf/timestamp"

export const protobufPackage = "itech.motorist.pksha.v1"

/**
 * A series of related events.
 *
 * A transaction represents a finite state machine of a parking space
 * reservation.
 */
export interface Transaction {
  /**
   * The resource name for the transaction.
   *
   * Format: transactions/{transaction}
   */
  name: string
  /** The client-generated reservation request id that created this transaction. */
  reservationRequestId: string
  /** The parking space name for the transaction. */
  parkingSpaceName: string
  /** The billing amount of the parking fee. */
  fee?: Money
  /** Series of events from older to newer. */
  events: Transaction_Event[]
  /** The parking of the transaction. */
  parking?: Parking
  /** Whether the transaction is used as ParkingGo. */
  parkingGoUsage: Transaction_ParkingGoUsage
}

/** A representation of a result of payment processing. */
export enum Transaction_PaymentProcessingResult {
  PAYMENT_PROCESSING_RESULT_INVALID = "PAYMENT_PROCESSING_RESULT_INVALID",
  /**
   * PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS - Payment was processed automatically (a.k.a. "自動精算")
   * and it was succeed.
   */
  PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS = "PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS",
  /**
   * PAYMENT_PROCESSING_RESULT_AUTO_FAILURE - Payment was processed automatically (a.k.a. "自動精算")
   * and it was failed.
   */
  PAYMENT_PROCESSING_RESULT_AUTO_FAILURE = "PAYMENT_PROCESSING_RESULT_AUTO_FAILURE",
  /**
   * PAYMENT_PROCESSING_RESULT_SEMI_AUTO - Payment was recognized as semi-automatic payment (a.k.a. "遅延精算")
   * and was not proceeded.
   */
  PAYMENT_PROCESSING_RESULT_SEMI_AUTO = "PAYMENT_PROCESSING_RESULT_SEMI_AUTO",
}

export function transaction_PaymentProcessingResultFromJSON(
  object: any
): Transaction_PaymentProcessingResult {
  switch (object) {
    case 0:
    case "PAYMENT_PROCESSING_RESULT_INVALID":
      return Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID
    case 1:
    case "PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS":
      return Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS
    case 2:
    case "PAYMENT_PROCESSING_RESULT_AUTO_FAILURE":
      return Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_FAILURE
    case 3:
    case "PAYMENT_PROCESSING_RESULT_SEMI_AUTO":
      return Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_SEMI_AUTO
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " +
          object +
          " for enum Transaction_PaymentProcessingResult"
      )
  }
}

export function transaction_PaymentProcessingResultToJSON(
  object: Transaction_PaymentProcessingResult
): string {
  switch (object) {
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID:
      return "PAYMENT_PROCESSING_RESULT_INVALID"
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS:
      return "PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS"
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_FAILURE:
      return "PAYMENT_PROCESSING_RESULT_AUTO_FAILURE"
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_SEMI_AUTO:
      return "PAYMENT_PROCESSING_RESULT_SEMI_AUTO"
    default:
      return "UNKNOWN"
  }
}

export function transaction_PaymentProcessingResultToNumber(
  object: Transaction_PaymentProcessingResult
): number {
  switch (object) {
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID:
      return 0
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_SUCCESS:
      return 1
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_FAILURE:
      return 2
    case Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_SEMI_AUTO:
      return 3
    default:
      return 0
  }
}

/**
 * ParkingGoUsage is the status of using ParkingGo. Users can switch the
 * status.
 */
export enum Transaction_ParkingGoUsage {
  PARKING_GO_USAGE_INVALID = "PARKING_GO_USAGE_INVALID",
  PARKING_GO_USAGE_ACTIVE = "PARKING_GO_USAGE_ACTIVE",
  PARKING_GO_USAGE_INACTIVE = "PARKING_GO_USAGE_INACTIVE",
}

export function transaction_ParkingGoUsageFromJSON(
  object: any
): Transaction_ParkingGoUsage {
  switch (object) {
    case 0:
    case "PARKING_GO_USAGE_INVALID":
      return Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID
    case 1:
    case "PARKING_GO_USAGE_ACTIVE":
      return Transaction_ParkingGoUsage.PARKING_GO_USAGE_ACTIVE
    case 2:
    case "PARKING_GO_USAGE_INACTIVE":
      return Transaction_ParkingGoUsage.PARKING_GO_USAGE_INACTIVE
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " +
          object +
          " for enum Transaction_ParkingGoUsage"
      )
  }
}

export function transaction_ParkingGoUsageToJSON(
  object: Transaction_ParkingGoUsage
): string {
  switch (object) {
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID:
      return "PARKING_GO_USAGE_INVALID"
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_ACTIVE:
      return "PARKING_GO_USAGE_ACTIVE"
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_INACTIVE:
      return "PARKING_GO_USAGE_INACTIVE"
    default:
      return "UNKNOWN"
  }
}

export function transaction_ParkingGoUsageToNumber(
  object: Transaction_ParkingGoUsage
): number {
  switch (object) {
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID:
      return 0
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_ACTIVE:
      return 1
    case Transaction_ParkingGoUsage.PARKING_GO_USAGE_INACTIVE:
      return 2
    default:
      return 0
  }
}

/** Event is an envelope of concrete events such as Reserved. */
export interface Transaction_Event {
  /** The reservation event. */
  reserved?: Transaction_Event_Reserved | undefined
  /** The reservation cancel event. */
  reservationCanceled?: Transaction_Event_ReservationCanceled | undefined
  /** The reservation cancel event. */
  reservationExpired?: Transaction_Event_ReservationExpired | undefined
  /** The entry detection event. */
  entryDetected?: Transaction_Event_EntryDetected | undefined
  /** The entry event. */
  entered?: Transaction_Event_Entered | undefined
  /** The entry acceptance event. */
  entryAccepted?: Transaction_Event_EntryAccepted | undefined
  /** The entry rejection event. */
  entryRejected?: Transaction_Event_EntryRejected | undefined
  /** The exit event. */
  exited?: Transaction_Event_Exited | undefined
  /** The exit event. */
  aborted?: Transaction_Event_Aborted | undefined
  /** The paid event. */
  paid?: Transaction_Event_Paid | undefined
}

/** A representation of reservation. */
export interface Transaction_Event_Reserved {
  /**
   * The resource name for the reservation.
   *
   * Format:
   * transactions/{transaction}/reservations/{reservation}
   */
  name: string
  /** The creation timestamp of this event. */
  createTime?: Date
}

/** A representation of reservation cancel event. */
export interface Transaction_Event_ReservationCanceled {
  /** The creation timestamp of this event. */
  createTime?: Date
}

/** A representation of reservation expiration event. */
export interface Transaction_Event_ReservationExpired {
  /** The creation timestamp of this event. */
  createTime?: Date
}

/** A representation of entry detection event. */
export interface Transaction_Event_EntryDetected {
  /** The creation timestamp of this event. */
  createTime?: Date
}

/** A representation of entry event. */
export interface Transaction_Event_Entered {
  /** The creation timestamp of this event. */
  createTime?: Date
}

/** A representation of entry acceptance event. */
export interface Transaction_Event_EntryAccepted {
  /** The creation timestamp of this event. */
  createTime?: Date
}

/** A representation of entry rejection event. */
export interface Transaction_Event_EntryRejected {
  /** The creation timestamp of this event. */
  createTime?: Date
}

/** A representation of exit event. */
export interface Transaction_Event_Exited {
  /**
   * The creation timestamp of this event.
   *
   * This field is set when a car exited from the parking space without
   * using the local adjustment machine. Note that this field can be empty
   * when the parking bill is settled by the local adjustment machine
   * in the parking.
   */
  createTime?: Date
  /** The payment processing result. */
  paymentProcessingResult: Transaction_PaymentProcessingResult
}

/** A representation of aborted event. */
export interface Transaction_Event_Aborted {
  /** The creation timestamp of this event. */
  createTime?: Date
}

/**
 * A representation of payment event.
 *
 * This field will be set by next actions.
 *
 * 1. auto system payment
 * 2. settlement by user
 */
export interface Transaction_Event_Paid {
  /** The creation timestamp of this event. */
  createTime?: Date
}

const baseTransaction: object = {
  name: "",
  reservationRequestId: "",
  parkingSpaceName: "",
  parkingGoUsage: Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID,
}

export const Transaction = {
  encode(
    message: Transaction,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name)
    }
    if (message.reservationRequestId !== "") {
      writer.uint32(18).string(message.reservationRequestId)
    }
    if (message.parkingSpaceName !== "") {
      writer.uint32(26).string(message.parkingSpaceName)
    }
    if (message.fee !== undefined) {
      Money.encode(message.fee, writer.uint32(34).fork()).ldelim()
    }
    for (const v of message.events) {
      Transaction_Event.encode(v!, writer.uint32(42).fork()).ldelim()
    }
    if (message.parking !== undefined) {
      Parking.encode(message.parking, writer.uint32(50).fork()).ldelim()
    }
    if (
      message.parkingGoUsage !==
      Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID
    ) {
      writer
        .uint32(56)
        .int32(transaction_ParkingGoUsageToNumber(message.parkingGoUsage))
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Transaction {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseTransaction } as Transaction
    message.events = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string()
          break
        case 2:
          message.reservationRequestId = reader.string()
          break
        case 3:
          message.parkingSpaceName = reader.string()
          break
        case 4:
          message.fee = Money.decode(reader, reader.uint32())
          break
        case 5:
          message.events.push(Transaction_Event.decode(reader, reader.uint32()))
          break
        case 6:
          message.parking = Parking.decode(reader, reader.uint32())
          break
        case 7:
          message.parkingGoUsage = transaction_ParkingGoUsageFromJSON(
            reader.int32()
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction {
    const message = { ...baseTransaction } as Transaction
    message.events = []
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name)
    } else {
      message.name = ""
    }
    if (
      object.reservationRequestId !== undefined &&
      object.reservationRequestId !== null
    ) {
      message.reservationRequestId = String(object.reservationRequestId)
    } else {
      message.reservationRequestId = ""
    }
    if (
      object.parkingSpaceName !== undefined &&
      object.parkingSpaceName !== null
    ) {
      message.parkingSpaceName = String(object.parkingSpaceName)
    } else {
      message.parkingSpaceName = ""
    }
    if (object.fee !== undefined && object.fee !== null) {
      message.fee = Money.fromJSON(object.fee)
    } else {
      message.fee = undefined
    }
    if (object.events !== undefined && object.events !== null) {
      for (const e of object.events) {
        message.events.push(Transaction_Event.fromJSON(e))
      }
    }
    if (object.parking !== undefined && object.parking !== null) {
      message.parking = Parking.fromJSON(object.parking)
    } else {
      message.parking = undefined
    }
    if (object.parkingGoUsage !== undefined && object.parkingGoUsage !== null) {
      message.parkingGoUsage = transaction_ParkingGoUsageFromJSON(
        object.parkingGoUsage
      )
    } else {
      message.parkingGoUsage =
        Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID
    }
    return message
  },

  toJSON(message: Transaction): unknown {
    const obj: any = {}
    message.name !== undefined && (obj.name = message.name)
    message.reservationRequestId !== undefined &&
      (obj.reservationRequestId = message.reservationRequestId)
    message.parkingSpaceName !== undefined &&
      (obj.parkingSpaceName = message.parkingSpaceName)
    message.fee !== undefined &&
      (obj.fee = message.fee ? Money.toJSON(message.fee) : undefined)
    if (message.events) {
      obj.events = message.events.map(e =>
        e ? Transaction_Event.toJSON(e) : undefined
      )
    } else {
      obj.events = []
    }
    message.parking !== undefined &&
      (obj.parking = message.parking
        ? Parking.toJSON(message.parking)
        : undefined)
    message.parkingGoUsage !== undefined &&
      (obj.parkingGoUsage = transaction_ParkingGoUsageToJSON(
        message.parkingGoUsage
      ))
    return obj
  },

  fromPartial(object: DeepPartial<Transaction>): Transaction {
    const message = { ...baseTransaction } as Transaction
    message.events = []
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name
    } else {
      message.name = ""
    }
    if (
      object.reservationRequestId !== undefined &&
      object.reservationRequestId !== null
    ) {
      message.reservationRequestId = object.reservationRequestId
    } else {
      message.reservationRequestId = ""
    }
    if (
      object.parkingSpaceName !== undefined &&
      object.parkingSpaceName !== null
    ) {
      message.parkingSpaceName = object.parkingSpaceName
    } else {
      message.parkingSpaceName = ""
    }
    if (object.fee !== undefined && object.fee !== null) {
      message.fee = Money.fromPartial(object.fee)
    } else {
      message.fee = undefined
    }
    if (object.events !== undefined && object.events !== null) {
      for (const e of object.events) {
        message.events.push(Transaction_Event.fromPartial(e))
      }
    }
    if (object.parking !== undefined && object.parking !== null) {
      message.parking = Parking.fromPartial(object.parking)
    } else {
      message.parking = undefined
    }
    if (object.parkingGoUsage !== undefined && object.parkingGoUsage !== null) {
      message.parkingGoUsage = object.parkingGoUsage
    } else {
      message.parkingGoUsage =
        Transaction_ParkingGoUsage.PARKING_GO_USAGE_INVALID
    }
    return message
  },
}

const baseTransaction_Event: object = {}

export const Transaction_Event = {
  encode(
    message: Transaction_Event,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.reserved !== undefined) {
      Transaction_Event_Reserved.encode(
        message.reserved,
        writer.uint32(10).fork()
      ).ldelim()
    }
    if (message.reservationCanceled !== undefined) {
      Transaction_Event_ReservationCanceled.encode(
        message.reservationCanceled,
        writer.uint32(18).fork()
      ).ldelim()
    }
    if (message.reservationExpired !== undefined) {
      Transaction_Event_ReservationExpired.encode(
        message.reservationExpired,
        writer.uint32(26).fork()
      ).ldelim()
    }
    if (message.entryDetected !== undefined) {
      Transaction_Event_EntryDetected.encode(
        message.entryDetected,
        writer.uint32(74).fork()
      ).ldelim()
    }
    if (message.entered !== undefined) {
      Transaction_Event_Entered.encode(
        message.entered,
        writer.uint32(34).fork()
      ).ldelim()
    }
    if (message.entryAccepted !== undefined) {
      Transaction_Event_EntryAccepted.encode(
        message.entryAccepted,
        writer.uint32(42).fork()
      ).ldelim()
    }
    if (message.entryRejected !== undefined) {
      Transaction_Event_EntryRejected.encode(
        message.entryRejected,
        writer.uint32(50).fork()
      ).ldelim()
    }
    if (message.exited !== undefined) {
      Transaction_Event_Exited.encode(
        message.exited,
        writer.uint32(58).fork()
      ).ldelim()
    }
    if (message.aborted !== undefined) {
      Transaction_Event_Aborted.encode(
        message.aborted,
        writer.uint32(82).fork()
      ).ldelim()
    }
    if (message.paid !== undefined) {
      Transaction_Event_Paid.encode(
        message.paid,
        writer.uint32(66).fork()
      ).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Transaction_Event {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseTransaction_Event } as Transaction_Event
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.reserved = Transaction_Event_Reserved.decode(
            reader,
            reader.uint32()
          )
          break
        case 2:
          message.reservationCanceled =
            Transaction_Event_ReservationCanceled.decode(
              reader,
              reader.uint32()
            )
          break
        case 3:
          message.reservationExpired =
            Transaction_Event_ReservationExpired.decode(reader, reader.uint32())
          break
        case 9:
          message.entryDetected = Transaction_Event_EntryDetected.decode(
            reader,
            reader.uint32()
          )
          break
        case 4:
          message.entered = Transaction_Event_Entered.decode(
            reader,
            reader.uint32()
          )
          break
        case 5:
          message.entryAccepted = Transaction_Event_EntryAccepted.decode(
            reader,
            reader.uint32()
          )
          break
        case 6:
          message.entryRejected = Transaction_Event_EntryRejected.decode(
            reader,
            reader.uint32()
          )
          break
        case 7:
          message.exited = Transaction_Event_Exited.decode(
            reader,
            reader.uint32()
          )
          break
        case 10:
          message.aborted = Transaction_Event_Aborted.decode(
            reader,
            reader.uint32()
          )
          break
        case 8:
          message.paid = Transaction_Event_Paid.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event {
    const message = { ...baseTransaction_Event } as Transaction_Event
    if (object.reserved !== undefined && object.reserved !== null) {
      message.reserved = Transaction_Event_Reserved.fromJSON(object.reserved)
    } else {
      message.reserved = undefined
    }
    if (
      object.reservationCanceled !== undefined &&
      object.reservationCanceled !== null
    ) {
      message.reservationCanceled =
        Transaction_Event_ReservationCanceled.fromJSON(
          object.reservationCanceled
        )
    } else {
      message.reservationCanceled = undefined
    }
    if (
      object.reservationExpired !== undefined &&
      object.reservationExpired !== null
    ) {
      message.reservationExpired =
        Transaction_Event_ReservationExpired.fromJSON(object.reservationExpired)
    } else {
      message.reservationExpired = undefined
    }
    if (object.entryDetected !== undefined && object.entryDetected !== null) {
      message.entryDetected = Transaction_Event_EntryDetected.fromJSON(
        object.entryDetected
      )
    } else {
      message.entryDetected = undefined
    }
    if (object.entered !== undefined && object.entered !== null) {
      message.entered = Transaction_Event_Entered.fromJSON(object.entered)
    } else {
      message.entered = undefined
    }
    if (object.entryAccepted !== undefined && object.entryAccepted !== null) {
      message.entryAccepted = Transaction_Event_EntryAccepted.fromJSON(
        object.entryAccepted
      )
    } else {
      message.entryAccepted = undefined
    }
    if (object.entryRejected !== undefined && object.entryRejected !== null) {
      message.entryRejected = Transaction_Event_EntryRejected.fromJSON(
        object.entryRejected
      )
    } else {
      message.entryRejected = undefined
    }
    if (object.exited !== undefined && object.exited !== null) {
      message.exited = Transaction_Event_Exited.fromJSON(object.exited)
    } else {
      message.exited = undefined
    }
    if (object.aborted !== undefined && object.aborted !== null) {
      message.aborted = Transaction_Event_Aborted.fromJSON(object.aborted)
    } else {
      message.aborted = undefined
    }
    if (object.paid !== undefined && object.paid !== null) {
      message.paid = Transaction_Event_Paid.fromJSON(object.paid)
    } else {
      message.paid = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event): unknown {
    const obj: any = {}
    message.reserved !== undefined &&
      (obj.reserved = message.reserved
        ? Transaction_Event_Reserved.toJSON(message.reserved)
        : undefined)
    message.reservationCanceled !== undefined &&
      (obj.reservationCanceled = message.reservationCanceled
        ? Transaction_Event_ReservationCanceled.toJSON(
            message.reservationCanceled
          )
        : undefined)
    message.reservationExpired !== undefined &&
      (obj.reservationExpired = message.reservationExpired
        ? Transaction_Event_ReservationExpired.toJSON(
            message.reservationExpired
          )
        : undefined)
    message.entryDetected !== undefined &&
      (obj.entryDetected = message.entryDetected
        ? Transaction_Event_EntryDetected.toJSON(message.entryDetected)
        : undefined)
    message.entered !== undefined &&
      (obj.entered = message.entered
        ? Transaction_Event_Entered.toJSON(message.entered)
        : undefined)
    message.entryAccepted !== undefined &&
      (obj.entryAccepted = message.entryAccepted
        ? Transaction_Event_EntryAccepted.toJSON(message.entryAccepted)
        : undefined)
    message.entryRejected !== undefined &&
      (obj.entryRejected = message.entryRejected
        ? Transaction_Event_EntryRejected.toJSON(message.entryRejected)
        : undefined)
    message.exited !== undefined &&
      (obj.exited = message.exited
        ? Transaction_Event_Exited.toJSON(message.exited)
        : undefined)
    message.aborted !== undefined &&
      (obj.aborted = message.aborted
        ? Transaction_Event_Aborted.toJSON(message.aborted)
        : undefined)
    message.paid !== undefined &&
      (obj.paid = message.paid
        ? Transaction_Event_Paid.toJSON(message.paid)
        : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<Transaction_Event>): Transaction_Event {
    const message = { ...baseTransaction_Event } as Transaction_Event
    if (object.reserved !== undefined && object.reserved !== null) {
      message.reserved = Transaction_Event_Reserved.fromPartial(object.reserved)
    } else {
      message.reserved = undefined
    }
    if (
      object.reservationCanceled !== undefined &&
      object.reservationCanceled !== null
    ) {
      message.reservationCanceled =
        Transaction_Event_ReservationCanceled.fromPartial(
          object.reservationCanceled
        )
    } else {
      message.reservationCanceled = undefined
    }
    if (
      object.reservationExpired !== undefined &&
      object.reservationExpired !== null
    ) {
      message.reservationExpired =
        Transaction_Event_ReservationExpired.fromPartial(
          object.reservationExpired
        )
    } else {
      message.reservationExpired = undefined
    }
    if (object.entryDetected !== undefined && object.entryDetected !== null) {
      message.entryDetected = Transaction_Event_EntryDetected.fromPartial(
        object.entryDetected
      )
    } else {
      message.entryDetected = undefined
    }
    if (object.entered !== undefined && object.entered !== null) {
      message.entered = Transaction_Event_Entered.fromPartial(object.entered)
    } else {
      message.entered = undefined
    }
    if (object.entryAccepted !== undefined && object.entryAccepted !== null) {
      message.entryAccepted = Transaction_Event_EntryAccepted.fromPartial(
        object.entryAccepted
      )
    } else {
      message.entryAccepted = undefined
    }
    if (object.entryRejected !== undefined && object.entryRejected !== null) {
      message.entryRejected = Transaction_Event_EntryRejected.fromPartial(
        object.entryRejected
      )
    } else {
      message.entryRejected = undefined
    }
    if (object.exited !== undefined && object.exited !== null) {
      message.exited = Transaction_Event_Exited.fromPartial(object.exited)
    } else {
      message.exited = undefined
    }
    if (object.aborted !== undefined && object.aborted !== null) {
      message.aborted = Transaction_Event_Aborted.fromPartial(object.aborted)
    } else {
      message.aborted = undefined
    }
    if (object.paid !== undefined && object.paid !== null) {
      message.paid = Transaction_Event_Paid.fromPartial(object.paid)
    } else {
      message.paid = undefined
    }
    return message
  },
}

const baseTransaction_Event_Reserved: object = { name: "" }

export const Transaction_Event_Reserved = {
  encode(
    message: Transaction_Event_Reserved,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name)
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(18).fork()
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_Reserved {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseTransaction_Event_Reserved,
    } as Transaction_Event_Reserved
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string()
          break
        case 2:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_Reserved {
    const message = {
      ...baseTransaction_Event_Reserved,
    } as Transaction_Event_Reserved
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name)
    } else {
      message.name = ""
    }
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event_Reserved): unknown {
    const obj: any = {}
    message.name !== undefined && (obj.name = message.name)
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_Reserved>
  ): Transaction_Event_Reserved {
    const message = {
      ...baseTransaction_Event_Reserved,
    } as Transaction_Event_Reserved
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name
    } else {
      message.name = ""
    }
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    return message
  },
}

const baseTransaction_Event_ReservationCanceled: object = {}

export const Transaction_Event_ReservationCanceled = {
  encode(
    message: Transaction_Event_ReservationCanceled,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(10).fork()
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_ReservationCanceled {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseTransaction_Event_ReservationCanceled,
    } as Transaction_Event_ReservationCanceled
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_ReservationCanceled {
    const message = {
      ...baseTransaction_Event_ReservationCanceled,
    } as Transaction_Event_ReservationCanceled
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event_ReservationCanceled): unknown {
    const obj: any = {}
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_ReservationCanceled>
  ): Transaction_Event_ReservationCanceled {
    const message = {
      ...baseTransaction_Event_ReservationCanceled,
    } as Transaction_Event_ReservationCanceled
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    return message
  },
}

const baseTransaction_Event_ReservationExpired: object = {}

export const Transaction_Event_ReservationExpired = {
  encode(
    message: Transaction_Event_ReservationExpired,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(10).fork()
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_ReservationExpired {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseTransaction_Event_ReservationExpired,
    } as Transaction_Event_ReservationExpired
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_ReservationExpired {
    const message = {
      ...baseTransaction_Event_ReservationExpired,
    } as Transaction_Event_ReservationExpired
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event_ReservationExpired): unknown {
    const obj: any = {}
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_ReservationExpired>
  ): Transaction_Event_ReservationExpired {
    const message = {
      ...baseTransaction_Event_ReservationExpired,
    } as Transaction_Event_ReservationExpired
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    return message
  },
}

const baseTransaction_Event_EntryDetected: object = {}

export const Transaction_Event_EntryDetected = {
  encode(
    message: Transaction_Event_EntryDetected,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(10).fork()
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_EntryDetected {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseTransaction_Event_EntryDetected,
    } as Transaction_Event_EntryDetected
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_EntryDetected {
    const message = {
      ...baseTransaction_Event_EntryDetected,
    } as Transaction_Event_EntryDetected
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event_EntryDetected): unknown {
    const obj: any = {}
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_EntryDetected>
  ): Transaction_Event_EntryDetected {
    const message = {
      ...baseTransaction_Event_EntryDetected,
    } as Transaction_Event_EntryDetected
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    return message
  },
}

const baseTransaction_Event_Entered: object = {}

export const Transaction_Event_Entered = {
  encode(
    message: Transaction_Event_Entered,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(10).fork()
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_Entered {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseTransaction_Event_Entered,
    } as Transaction_Event_Entered
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_Entered {
    const message = {
      ...baseTransaction_Event_Entered,
    } as Transaction_Event_Entered
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event_Entered): unknown {
    const obj: any = {}
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_Entered>
  ): Transaction_Event_Entered {
    const message = {
      ...baseTransaction_Event_Entered,
    } as Transaction_Event_Entered
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    return message
  },
}

const baseTransaction_Event_EntryAccepted: object = {}

export const Transaction_Event_EntryAccepted = {
  encode(
    message: Transaction_Event_EntryAccepted,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(10).fork()
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_EntryAccepted {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseTransaction_Event_EntryAccepted,
    } as Transaction_Event_EntryAccepted
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_EntryAccepted {
    const message = {
      ...baseTransaction_Event_EntryAccepted,
    } as Transaction_Event_EntryAccepted
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event_EntryAccepted): unknown {
    const obj: any = {}
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_EntryAccepted>
  ): Transaction_Event_EntryAccepted {
    const message = {
      ...baseTransaction_Event_EntryAccepted,
    } as Transaction_Event_EntryAccepted
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    return message
  },
}

const baseTransaction_Event_EntryRejected: object = {}

export const Transaction_Event_EntryRejected = {
  encode(
    message: Transaction_Event_EntryRejected,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(10).fork()
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_EntryRejected {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseTransaction_Event_EntryRejected,
    } as Transaction_Event_EntryRejected
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_EntryRejected {
    const message = {
      ...baseTransaction_Event_EntryRejected,
    } as Transaction_Event_EntryRejected
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event_EntryRejected): unknown {
    const obj: any = {}
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_EntryRejected>
  ): Transaction_Event_EntryRejected {
    const message = {
      ...baseTransaction_Event_EntryRejected,
    } as Transaction_Event_EntryRejected
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    return message
  },
}

const baseTransaction_Event_Exited: object = {
  paymentProcessingResult:
    Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID,
}

export const Transaction_Event_Exited = {
  encode(
    message: Transaction_Event_Exited,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(10).fork()
      ).ldelim()
    }
    if (
      message.paymentProcessingResult !==
      Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID
    ) {
      writer
        .uint32(16)
        .int32(
          transaction_PaymentProcessingResultToNumber(
            message.paymentProcessingResult
          )
        )
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_Exited {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseTransaction_Event_Exited,
    } as Transaction_Event_Exited
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        case 2:
          message.paymentProcessingResult =
            transaction_PaymentProcessingResultFromJSON(reader.int32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_Exited {
    const message = {
      ...baseTransaction_Event_Exited,
    } as Transaction_Event_Exited
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    if (
      object.paymentProcessingResult !== undefined &&
      object.paymentProcessingResult !== null
    ) {
      message.paymentProcessingResult =
        transaction_PaymentProcessingResultFromJSON(
          object.paymentProcessingResult
        )
    } else {
      message.paymentProcessingResult =
        Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID
    }
    return message
  },

  toJSON(message: Transaction_Event_Exited): unknown {
    const obj: any = {}
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    message.paymentProcessingResult !== undefined &&
      (obj.paymentProcessingResult = transaction_PaymentProcessingResultToJSON(
        message.paymentProcessingResult
      ))
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_Exited>
  ): Transaction_Event_Exited {
    const message = {
      ...baseTransaction_Event_Exited,
    } as Transaction_Event_Exited
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    if (
      object.paymentProcessingResult !== undefined &&
      object.paymentProcessingResult !== null
    ) {
      message.paymentProcessingResult = object.paymentProcessingResult
    } else {
      message.paymentProcessingResult =
        Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_INVALID
    }
    return message
  },
}

const baseTransaction_Event_Aborted: object = {}

export const Transaction_Event_Aborted = {
  encode(
    message: Transaction_Event_Aborted,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(10).fork()
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_Aborted {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = {
      ...baseTransaction_Event_Aborted,
    } as Transaction_Event_Aborted
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_Aborted {
    const message = {
      ...baseTransaction_Event_Aborted,
    } as Transaction_Event_Aborted
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event_Aborted): unknown {
    const obj: any = {}
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_Aborted>
  ): Transaction_Event_Aborted {
    const message = {
      ...baseTransaction_Event_Aborted,
    } as Transaction_Event_Aborted
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    return message
  },
}

const baseTransaction_Event_Paid: object = {}

export const Transaction_Event_Paid = {
  encode(
    message: Transaction_Event_Paid,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(
        toTimestamp(message.createTime),
        writer.uint32(10).fork()
      ).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): Transaction_Event_Paid {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseTransaction_Event_Paid } as Transaction_Event_Paid
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.createTime = fromTimestamp(
            Timestamp.decode(reader, reader.uint32())
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Transaction_Event_Paid {
    const message = { ...baseTransaction_Event_Paid } as Transaction_Event_Paid
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = fromJsonTimestamp(object.createTime)
    } else {
      message.createTime = undefined
    }
    return message
  },

  toJSON(message: Transaction_Event_Paid): unknown {
    const obj: any = {}
    message.createTime !== undefined &&
      (obj.createTime = message.createTime.toISOString())
    return obj
  },

  fromPartial(
    object: DeepPartial<Transaction_Event_Paid>
  ): Transaction_Event_Paid {
    const message = { ...baseTransaction_Event_Paid } as Transaction_Event_Paid
    if (object.createTime !== undefined && object.createTime !== null) {
      message.createTime = object.createTime
    } else {
      message.createTime = undefined
    }
    return message
  },
}

declare var self: any | undefined
declare var window: any | undefined
declare var global: any | undefined
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis
  if (typeof self !== "undefined") return self
  if (typeof window !== "undefined") return window
  if (typeof global !== "undefined") return global
  throw "Unable to locate global object"
})()

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000
  const nanos = (date.getTime() % 1_000) * 1_000_000
  return { seconds, nanos }
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000
  millis += t.nanos / 1_000_000
  return new Date(millis)
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o
  } else if (typeof o === "string") {
    return new Date(o)
  } else {
    return fromTimestamp(Timestamp.fromJSON(o))
  }
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any
  _m0.configure()
}
