import React, { ReactElement } from "react"
import {
  Parking,
  ParkingAvailability_Summary,
  Parking_Type,
} from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import {
  DefaultInfoRow,
  AddressInfoRow,
  AvailableServicesInfoRow,
  FeesInfoRow,
} from "../common"
import * as styles from "./parking-detail-info-table.module.scss"

interface Props {
  parking: Parking
  zipcode: string | null
}

export const ParkingDetailInfoTable = React.memo(
  function ParkingDetailInfoTable(props: Props): ReactElement {
    const { parking, zipcode } = props

    function handicappedSpaces(): string {
      return parking.handicappedSpaceNumbers.length > 0
        ? `${parking.handicappedSpaceNumbers.join(", ")}が車椅子対応車室です。`
        : "未対応"
    }

    function totalSpaceCount(): string {
      const availability = parking.availability
      if (
        availability == null ||
        availability.summary == ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE
      ) {
        return "不明"
      }
      return `${availability.totalSpaceCount}車室`
    }

    function typeText(): string {
      switch (parking.type) {
        case Parking_Type.TYPE_INVALID:
          return "不明"
        case Parking_Type.TYPE_LOCK:
          return "ロック板"
        case Parking_Type.TYPE_LOCKLESS:
          return "ロックレス"
        case Parking_Type.TYPE_GATE:
          return "ゲート式"
        case Parking_Type.TYPE_PHSLOCK:
          return "PHS式ロック板"
      }
    }
    return (
      <div className={styles.container}>
        <AddressInfoRow parking={parking} zipcode={zipcode} />
        <DefaultInfoRow column={"車室数"} value={totalSpaceCount()} />
        <FeesInfoRow parking={parking} />
        <DefaultInfoRow column={"設備"} value={handicappedSpaces()} />
        <AvailableServicesInfoRow parking={parking} />
        <DefaultInfoRow column={"駐車場タイプ"} value={typeText()} />
        <DefaultInfoRow column={"備考"} value={parking.note} />
      </div>
    )
  }
)
