export class User {
  constructor(public name: string, public paymentMethod: string) {}

  isPayable(): boolean {
    return (
      this.paymentMethod === "CREDIT_CARD" ||
      this.paymentMethod === "WITHDRAWAL"
    )
  }
}
