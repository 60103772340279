/* eslint-disable */
import Long from "long"
import _m0 from "protobufjs/minimal"

export const protobufPackage = "google.type"

/**
 * Represents a whole or partial calendar date, such as a birthday. The time of
 * day and time zone are either specified elsewhere or are insignificant. The
 * date is relative to the Gregorian Calendar. This can represent one of the
 * following:
 *
 * * A full date, with non-zero year, month, and day values
 * * A month and day value, with a zero year, such as an anniversary
 * * A year on its own, with zero month and day values
 * * A year and month value, with a zero day, such as a credit card expiration
 * date
 *
 * Related types are [google.type.TimeOfDay][google.type.TimeOfDay] and
 * `google.protobuf.Timestamp`.
 */
export interface DateMessage {
  /**
   * Year of the date. Must be from 1 to 9999, or 0 to specify a date without
   * a year.
   */
  year: number
  /**
   * Month of a year. Must be from 1 to 12, or 0 to specify a year without a
   * month and day.
   */
  month: number
  /**
   * Day of a month. Must be from 1 to 31 and valid for the year and month, or 0
   * to specify a year by itself or a year and month where the day isn't
   * significant.
   */
  day: number
}

const baseDateMessage: object = { year: 0, month: 0, day: 0 }

export const DateMessage = {
  encode(
    message: DateMessage,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.year !== 0) {
      writer.uint32(8).int32(message.year)
    }
    if (message.month !== 0) {
      writer.uint32(16).int32(message.month)
    }
    if (message.day !== 0) {
      writer.uint32(24).int32(message.day)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DateMessage {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseDateMessage } as DateMessage
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.year = reader.int32()
          break
        case 2:
          message.month = reader.int32()
          break
        case 3:
          message.day = reader.int32()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): DateMessage {
    const message = { ...baseDateMessage } as DateMessage
    if (object.year !== undefined && object.year !== null) {
      message.year = Number(object.year)
    } else {
      message.year = 0
    }
    if (object.month !== undefined && object.month !== null) {
      message.month = Number(object.month)
    } else {
      message.month = 0
    }
    if (object.day !== undefined && object.day !== null) {
      message.day = Number(object.day)
    } else {
      message.day = 0
    }
    return message
  },

  toJSON(message: DateMessage): unknown {
    const obj: any = {}
    message.year !== undefined && (obj.year = message.year)
    message.month !== undefined && (obj.month = message.month)
    message.day !== undefined && (obj.day = message.day)
    return obj
  },

  fromPartial(object: DeepPartial<DateMessage>): DateMessage {
    const message = { ...baseDateMessage } as DateMessage
    if (object.year !== undefined && object.year !== null) {
      message.year = object.year
    } else {
      message.year = 0
    }
    if (object.month !== undefined && object.month !== null) {
      message.month = object.month
    } else {
      message.month = 0
    }
    if (object.day !== undefined && object.day !== null) {
      message.day = object.day
    } else {
      message.day = 0
    }
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any
  _m0.configure()
}
