import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import type {
  Transaction as ProtoTransaction,
  Transaction_Event as ProtoTransactionEvent,
  Transaction_Event_Aborted,
  Transaction_Event_Entered,
  Transaction_Event_EntryAccepted,
  Transaction_Event_EntryDetected,
  Transaction_Event_EntryRejected,
  Transaction_Event_Exited,
  Transaction_Event_Paid,
  Transaction_Event_ReservationCanceled,
  Transaction_Event_ReservationExpired,
  Transaction_Event_Reserved,
} from "../../__generated__/proto/itech/motorist/pksha/v1/transaction"
import { Transaction_PaymentProcessingResult } from "../../__generated__/proto/itech/motorist/pksha/v1/transaction"
import { TransactionEventType } from "../../types/transaction-event-type"

export class Transaction {
  private constructor(private transaction: ProtoTransaction) {}

  static fromProto(pTxn: ProtoTransaction): Transaction {
    return new Transaction(pTxn)
  }

  get name(): string {
    return this.transaction.name
  }

  get parking(): Parking | undefined {
    return this.transaction.parking
  }

  get parkingSpaceName(): string {
    return this.transaction.parkingSpaceName
  }

  get parkingSpaceNumber(): string | undefined {
    return this.transaction.parkingSpaceName.split("/").pop()
  }

  get fee(): number | undefined {
    return this.transaction.fee?.units
  }

  get latestEvent(): TransactionEvent | undefined {
    const e = this.transaction.events.slice(-1)[0]
    return e ? TransactionEvent.fromProto(e) : undefined
  }

  private findEvent(
    eventType: TransactionEventType
  ): TransactionEvent | undefined {
    const e = this.transaction.events.find(e => {
      return (
        (eventType === "reserved" && e.reserved) ||
        (eventType === "reservationCanceled" && e.reservationCanceled) ||
        (eventType === "reservationExpired" && e.reservationExpired) ||
        (eventType === "entryDetected" && e.entryDetected) ||
        (eventType === "entered" && e.entered) ||
        (eventType === "entryAccepted" && e.entryAccepted) ||
        (eventType === "entryRejected" && e.entryRejected) ||
        (eventType === "exited" && e.exited) ||
        (eventType === "aborted" && e.aborted) ||
        (eventType === "paid" && e.paid)
      )
    })
    return e ? TransactionEvent.fromProto(e) : undefined
  }

  get reserved(): Transaction_Event_Reserved | undefined {
    return this.findEvent("reserved")?.reserved
  }

  get reservationCanceled(): Transaction_Event_ReservationCanceled | undefined {
    return this.findEvent("reservationCanceled")?.reservationCanceled
  }

  get reservationExpired(): Transaction_Event_ReservationExpired | undefined {
    return this.findEvent("reservationExpired")?.reservationExpired
  }

  get entryDetected(): Transaction_Event_EntryDetected | undefined {
    return this.findEvent("entryDetected")?.entryDetected
  }

  get entered(): Transaction_Event_Entered | undefined {
    return this.findEvent("entered")?.entered
  }

  get entryAccepted(): Transaction_Event_EntryAccepted | undefined {
    return this.findEvent("entryAccepted")?.entryAccepted
  }

  get entryRejected(): Transaction_Event_EntryRejected | undefined {
    return this.findEvent("entryRejected")?.entryRejected
  }

  get exited(): Transaction_Event_Exited | undefined {
    return this.findEvent("exited")?.exited
  }

  get aborted(): Transaction_Event_Aborted | undefined {
    return this.findEvent("aborted")?.aborted
  }

  get paid(): Transaction_Event_Paid | undefined {
    return this.findEvent("paid")?.paid
  }
}

export class TransactionEvent {
  private constructor(private transactionEvent: ProtoTransactionEvent) {}

  static fromProto(pEvent: ProtoTransactionEvent): TransactionEvent {
    return new TransactionEvent(pEvent)
  }

  get type(): TransactionEventType {
    if (this.isReserved) {
      return "reserved"
    }
    if (this.isReservationCanceled) {
      return "reservationCanceled"
    }
    if (this.isReservationExpired) {
      return "reservationExpired"
    }
    if (this.isEntryDetected) {
      return "entryDetected"
    }
    if (this.isEntered) {
      return "entered"
    }
    if (this.isEntryAccepted) {
      return "entryAccepted"
    }
    if (this.isEntryRejected) {
      return "entryRejected"
    }
    if (this.isExited) {
      return "exited"
    }
    if (this.isAborted) {
      return "aborted"
    }
    if (this.isPaid) {
      return "paid"
    }

    // NOT REACHED
    throw new Error("Unknown transaction event type")
  }

  get isReserved(): boolean {
    return this.reserved != null
  }

  get isReservationCanceled(): boolean {
    return this.reservationCanceled != null
  }

  get isReservationExpired(): boolean {
    return this.reservationExpired != null
  }

  get isEntryDetected(): boolean {
    return this.entryDetected != null
  }

  get isEntered(): boolean {
    return this.entered != null
  }

  get isEntryAccepted(): boolean {
    return this.entryAccepted != null
  }

  get isEntryRejected(): boolean {
    return this.entryRejected != null
  }

  get isExited(): boolean {
    return this.exited != null
  }

  get isAborted(): boolean {
    return this.aborted != null
  }

  get isPaid(): boolean {
    return this.paid != null
  }

  get requiresAction(): boolean {
    return this.isEntryDetected || this.isEntered
  }

  get isConcluded(): boolean {
    return (
      this.isReservationCanceled ||
      this.isReservationExpired ||
      this.isEntryRejected ||
      this.isExited ||
      this.isAborted ||
      this.isPaid
    )
  }

  get isPaymentProcessingResultAutoFailure(): boolean {
    return (
      this.exited?.paymentProcessingResult ===
      Transaction_PaymentProcessingResult.PAYMENT_PROCESSING_RESULT_AUTO_FAILURE
    )
  }

  get reserved(): Transaction_Event_Reserved | undefined {
    return this.transactionEvent.reserved
  }

  get reservationCanceled(): Transaction_Event_ReservationCanceled | undefined {
    return this.transactionEvent.reservationCanceled
  }

  get reservationExpired(): Transaction_Event_ReservationExpired | undefined {
    return this.transactionEvent.reservationExpired
  }

  get entryDetected(): Transaction_Event_EntryDetected | undefined {
    return this.transactionEvent.entryDetected
  }

  get entered(): Transaction_Event_Entered | undefined {
    return this.transactionEvent.entered
  }

  get entryAccepted(): Transaction_Event_EntryAccepted | undefined {
    return this.transactionEvent.entryAccepted
  }

  get entryRejected(): Transaction_Event_EntryRejected | undefined {
    return this.transactionEvent.entryRejected
  }

  get exited(): Transaction_Event_Exited | undefined {
    return this.transactionEvent.exited
  }

  get aborted(): Transaction_Event_Aborted | undefined {
    return this.transactionEvent.aborted
  }

  get paid(): Transaction_Event_Paid | undefined {
    return this.transactionEvent.paid
  }
}
