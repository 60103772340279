import React, { ReactElement } from "react"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { DefaultInfoRow, FeesInfoRow } from "../common"
import * as styles from "./parking-reservation-info-table.module.scss"

interface Props {
  parking: Parking
}

export const ParkingReservationInfoTable = React.memo(
  function ParkingReservationInfoTable(props: Props): ReactElement {
    // MARK: - Variables

    const { parking } = props

    // MARK: - Render

    return (
      <div className={styles.container}>
        <DefaultInfoRow column={"駐車場名"} value={parking.displayName} />
        <DefaultInfoRow column={"住所"} value={parking.fullAddress} />
        <FeesInfoRow parking={parking} />
        <DefaultInfoRow column={"予約可能な車室"} value={reservableSpaces()} />
        <DefaultInfoRow column={"車椅子対応車室"} value={handicappedSpaces()} />
        <DefaultInfoRow column={"備考"} value={parking.note} />
      </div>
    )

    // MARK: - Methods

    function handicappedSpaces(): string {
      return parking.handicappedSpaceNumbers.length > 0
        ? `${parking.handicappedSpaceNumbers.join(", ")}が車椅子対応車室です。`
        : "未対応"
    }

    function reservableSpaces(): string {
      return parking.reservableSpaceNumbers.length > 0
        ? `${parking.reservableSpaceNumbers.join(", ")}の車室のみ予約できます。`
        : "未対応"
    }
  }
)
