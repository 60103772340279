/* eslint-disable */
import Long from "long"
import _m0 from "protobufjs/minimal"

export const protobufPackage = "google.type"

/** Represents an amount of money with its currency type. */
export interface Money {
  /** The three-letter currency code defined in ISO 4217. */
  currencyCode: string
  /**
   * The whole units of the amount.
   * For example if `currencyCode` is `"USD"`, then 1 unit is one US dollar.
   */
  units: number
  /**
   * Number of nano (10^-9) units of the amount.
   * The value must be between -999,999,999 and +999,999,999 inclusive.
   * If `units` is positive, `nanos` must be positive or zero.
   * If `units` is zero, `nanos` can be positive, zero, or negative.
   * If `units` is negative, `nanos` must be negative or zero.
   * For example $-1.75 is represented as `units`=-1 and `nanos`=-750,000,000.
   */
  nanos: number
}

const baseMoney: object = { currencyCode: "", units: 0, nanos: 0 }

export const Money = {
  encode(message: Money, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currencyCode !== "") {
      writer.uint32(10).string(message.currencyCode)
    }
    if (message.units !== 0) {
      writer.uint32(16).int64(message.units)
    }
    if (message.nanos !== 0) {
      writer.uint32(24).int32(message.nanos)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Money {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseMoney } as Money
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.currencyCode = reader.string()
          break
        case 2:
          message.units = longToNumber(reader.int64() as Long)
          break
        case 3:
          message.nanos = reader.int32()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Money {
    const message = { ...baseMoney } as Money
    if (object.currencyCode !== undefined && object.currencyCode !== null) {
      message.currencyCode = String(object.currencyCode)
    } else {
      message.currencyCode = ""
    }
    if (object.units !== undefined && object.units !== null) {
      message.units = Number(object.units)
    } else {
      message.units = 0
    }
    if (object.nanos !== undefined && object.nanos !== null) {
      message.nanos = Number(object.nanos)
    } else {
      message.nanos = 0
    }
    return message
  },

  toJSON(message: Money): unknown {
    const obj: any = {}
    message.currencyCode !== undefined &&
      (obj.currencyCode = message.currencyCode)
    message.units !== undefined && (obj.units = message.units)
    message.nanos !== undefined && (obj.nanos = message.nanos)
    return obj
  },

  fromPartial(object: DeepPartial<Money>): Money {
    const message = { ...baseMoney } as Money
    if (object.currencyCode !== undefined && object.currencyCode !== null) {
      message.currencyCode = object.currencyCode
    } else {
      message.currencyCode = ""
    }
    if (object.units !== undefined && object.units !== null) {
      message.units = object.units
    } else {
      message.units = 0
    }
    if (object.nanos !== undefined && object.nanos !== null) {
      message.nanos = object.nanos
    } else {
      message.nanos = 0
    }
    return message
  },
}

declare var self: any | undefined
declare var window: any | undefined
declare var global: any | undefined
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis
  if (typeof self !== "undefined") return self
  if (typeof window !== "undefined") return window
  if (typeof global !== "undefined") return global
  throw "Unable to locate global object"
})()

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER")
  }
  return long.toNumber()
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any
  _m0.configure()
}
