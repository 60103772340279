/* eslint-disable */
import Long from "long"
import _m0 from "protobufjs/minimal"
import { LatLng } from "../../../../google/type/latlng"
import { ParkingBusinessOwner } from "../../../../itech/motorist/pksha/v1/parking_business_owner"
import { TimeOfDay } from "../../../../google/type/timeofday"
import { Money } from "../../../../google/type/money"

export const protobufPackage = "itech.motorist.pksha.v1"

/** A representation of parking. */
export interface Parking {
  /**
   * The resource name for the parking.
   *
   * Format: parkings/{parking}
   */
  name: string
  /** The display name of the parking. */
  displayName: string
  /** The full address of the parking. */
  fullAddress: string
  /** The type of the parking. */
  type: Parking_Type
  /** The location of the parking. */
  latLng?: LatLng
  /** The current availability of the parking. */
  availability?: ParkingAvailability
  /**
   * The origin of the parking.
   *
   * NOTE: We really didn't want to expose the origin but raw id was required
   * for clients to interact with the legacy api.
   */
  origin?: Parking_Origin
  /**
   * If the parking is reservable. The reservation method may vary depends on
   * the parking's origin.
   */
  reservable: boolean
  /** The fee table of the parking. */
  feeTable?: FeeTable
  /** The room numbers of the handicapped parking space. */
  handicappedSpaceNumbers: string[]
  /** If the parking is available to QT-net. */
  isQtNetAvailable: boolean
  /** If the parking is available to SmartPay. */
  isSmartPayAvailable: boolean
  /** The note of parking. */
  note: string
  /** The researvable parking space numbers. */
  reservableSpaceNumbers: string[]
  /** The state of the parking go availability. */
  parkingGoState: Parking_ParkingGoState
  /** If the parking is enabled mobile corporate card. */
  isMobileCorporateCardAvailable: boolean
  /** A representation of the parking business owner. */
  parkingBusinessOwner?: ParkingBusinessOwner
}

/** A type of parking. */
export enum Parking_Type {
  TYPE_INVALID = "TYPE_INVALID",
  /** TYPE_LOCK - The parking has flaps to lock each lot. */
  TYPE_LOCK = "TYPE_LOCK",
  /** TYPE_LOCKLESS - The parking has no flaps to lock. */
  TYPE_LOCKLESS = "TYPE_LOCKLESS",
  /** TYPE_GATE - The parking has a gate in the entrance. */
  TYPE_GATE = "TYPE_GATE",
  /**
   * TYPE_PHSLOCK - The parking has flaps to lock each lot and use PHS connection.
   * PHS type is legacy, so Itech is replacing it to LTE network.
   */
  TYPE_PHSLOCK = "TYPE_PHSLOCK",
}

export function parking_TypeFromJSON(object: any): Parking_Type {
  switch (object) {
    case 0:
    case "TYPE_INVALID":
      return Parking_Type.TYPE_INVALID
    case 1:
    case "TYPE_LOCK":
      return Parking_Type.TYPE_LOCK
    case 2:
    case "TYPE_LOCKLESS":
      return Parking_Type.TYPE_LOCKLESS
    case 3:
    case "TYPE_GATE":
      return Parking_Type.TYPE_GATE
    case 4:
    case "TYPE_PHSLOCK":
      return Parking_Type.TYPE_PHSLOCK
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum Parking_Type"
      )
  }
}

export function parking_TypeToJSON(object: Parking_Type): string {
  switch (object) {
    case Parking_Type.TYPE_INVALID:
      return "TYPE_INVALID"
    case Parking_Type.TYPE_LOCK:
      return "TYPE_LOCK"
    case Parking_Type.TYPE_LOCKLESS:
      return "TYPE_LOCKLESS"
    case Parking_Type.TYPE_GATE:
      return "TYPE_GATE"
    case Parking_Type.TYPE_PHSLOCK:
      return "TYPE_PHSLOCK"
    default:
      return "UNKNOWN"
  }
}

export function parking_TypeToNumber(object: Parking_Type): number {
  switch (object) {
    case Parking_Type.TYPE_INVALID:
      return 0
    case Parking_Type.TYPE_LOCK:
      return 1
    case Parking_Type.TYPE_LOCKLESS:
      return 2
    case Parking_Type.TYPE_GATE:
      return 3
    case Parking_Type.TYPE_PHSLOCK:
      return 4
    default:
      return 0
  }
}

/** The state whether parking go is available or not. */
export enum Parking_ParkingGoState {
  PARKING_GO_STATE_INVALID = "PARKING_GO_STATE_INVALID",
  /** PARKING_GO_STATE_UNAVAILABLE - ParkingGo is not available */
  PARKING_GO_STATE_UNAVAILABLE = "PARKING_GO_STATE_UNAVAILABLE",
  /** PARKING_GO_STATE_AVAILABLE - ParkingGo is available. */
  PARKING_GO_STATE_AVAILABLE = "PARKING_GO_STATE_AVAILABLE",
}

export function parking_ParkingGoStateFromJSON(
  object: any
): Parking_ParkingGoState {
  switch (object) {
    case 0:
    case "PARKING_GO_STATE_INVALID":
      return Parking_ParkingGoState.PARKING_GO_STATE_INVALID
    case 1:
    case "PARKING_GO_STATE_UNAVAILABLE":
      return Parking_ParkingGoState.PARKING_GO_STATE_UNAVAILABLE
    case 2:
    case "PARKING_GO_STATE_AVAILABLE":
      return Parking_ParkingGoState.PARKING_GO_STATE_AVAILABLE
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum Parking_ParkingGoState"
      )
  }
}

export function parking_ParkingGoStateToJSON(
  object: Parking_ParkingGoState
): string {
  switch (object) {
    case Parking_ParkingGoState.PARKING_GO_STATE_INVALID:
      return "PARKING_GO_STATE_INVALID"
    case Parking_ParkingGoState.PARKING_GO_STATE_UNAVAILABLE:
      return "PARKING_GO_STATE_UNAVAILABLE"
    case Parking_ParkingGoState.PARKING_GO_STATE_AVAILABLE:
      return "PARKING_GO_STATE_AVAILABLE"
    default:
      return "UNKNOWN"
  }
}

export function parking_ParkingGoStateToNumber(
  object: Parking_ParkingGoState
): number {
  switch (object) {
    case Parking_ParkingGoState.PARKING_GO_STATE_INVALID:
      return 0
    case Parking_ParkingGoState.PARKING_GO_STATE_UNAVAILABLE:
      return 1
    case Parking_ParkingGoState.PARKING_GO_STATE_AVAILABLE:
      return 2
    default:
      return 0
  }
}

/** A (supplier, raw_id) tuple that identifies imported parking origin. */
export interface Parking_Origin {
  /**
   * The supplier of a parking data, typically a company identifier such as
   * itech or peasy.
   */
  supplier: string
  /** The parking raw id that is used by the supplier. */
  rawId: string
}

/** A representation of parking's availability such as number parked cars. */
export interface ParkingAvailability {
  /** The total number of parking spaces. */
  totalSpaceCount: number
  /** The number of parking spaces that is in use. */
  inUseSpaceCount: number
  /** The availability summary. */
  summary: ParkingAvailability_Summary
}

/** Summary of availability. */
export enum ParkingAvailability_Summary {
  SUMMARY_INVALID = "SUMMARY_INVALID",
  /** SUMMARY_EMPTY - Many spaces are available. */
  SUMMARY_EMPTY = "SUMMARY_EMPTY",
  /** SUMMARY_CROWDED - Many spaces are in use. */
  SUMMARY_CROWDED = "SUMMARY_CROWDED",
  /** SUMMARY_FULL - All spaces are in use. */
  SUMMARY_FULL = "SUMMARY_FULL",
  /** SUMMARY_UNOBTAINABLE - The availability is unobtainable when Type is TYPE_PHSLOCK. */
  SUMMARY_UNOBTAINABLE = "SUMMARY_UNOBTAINABLE",
}

export function parkingAvailability_SummaryFromJSON(
  object: any
): ParkingAvailability_Summary {
  switch (object) {
    case 0:
    case "SUMMARY_INVALID":
      return ParkingAvailability_Summary.SUMMARY_INVALID
    case 1:
    case "SUMMARY_EMPTY":
      return ParkingAvailability_Summary.SUMMARY_EMPTY
    case 2:
    case "SUMMARY_CROWDED":
      return ParkingAvailability_Summary.SUMMARY_CROWDED
    case 3:
    case "SUMMARY_FULL":
      return ParkingAvailability_Summary.SUMMARY_FULL
    case 4:
    case "SUMMARY_UNOBTAINABLE":
      return ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " +
          object +
          " for enum ParkingAvailability_Summary"
      )
  }
}

export function parkingAvailability_SummaryToJSON(
  object: ParkingAvailability_Summary
): string {
  switch (object) {
    case ParkingAvailability_Summary.SUMMARY_INVALID:
      return "SUMMARY_INVALID"
    case ParkingAvailability_Summary.SUMMARY_EMPTY:
      return "SUMMARY_EMPTY"
    case ParkingAvailability_Summary.SUMMARY_CROWDED:
      return "SUMMARY_CROWDED"
    case ParkingAvailability_Summary.SUMMARY_FULL:
      return "SUMMARY_FULL"
    case ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE:
      return "SUMMARY_UNOBTAINABLE"
    default:
      return "UNKNOWN"
  }
}

export function parkingAvailability_SummaryToNumber(
  object: ParkingAvailability_Summary
): number {
  switch (object) {
    case ParkingAvailability_Summary.SUMMARY_INVALID:
      return 0
    case ParkingAvailability_Summary.SUMMARY_EMPTY:
      return 1
    case ParkingAvailability_Summary.SUMMARY_CROWDED:
      return 2
    case ParkingAvailability_Summary.SUMMARY_FULL:
      return 3
    case ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE:
      return 4
    default:
      return 0
  }
}

/** A representation of daily parking's fee list. */
export interface FeeTable {
  /** The fee list for weekday. */
  weekdayFees: FeeUnit[]
  /** The fee list for saturday. */
  saturdayFees: FeeUnit[]
  /** The fee list for sunday. */
  sundayFees: FeeUnit[]
  /** The fee list for holiday. */
  holidayFees: FeeUnit[]
  /** The max fee infomation for weekday. */
  weekdayMaxFee?: SubMaxFee
  /** The max fee infomation for saturday. */
  saturdayMaxFee?: SubMaxFee
  /** The max fee infomation for sunday. */
  sundayMaxFee?: SubMaxFee
  /** The max fee infomation for holiday. */
  holidayMaxFee?: SubMaxFee
}

/** A representation of parking's pricing by time of day. */
export interface FeeUnit {
  /**
   * The starting time of the time period that this pricing is applied to. This
   * start_time is inclusive value. [start_time, end_time)
   */
  startTime?: TimeOfDay
  /**
   * The end time of the time period that this pricing is applied to. This
   * end_time is exlusive value (half-closed intervals). [start_time, end_time)
   */
  endTime?: TimeOfDay
  /**
   * The Unit duration to be charged for the time period that this pricing is
   * applied to.
   */
  unitDuration: string
  /**
   * The fee per unit_duration of the time period that this pricing is applied
   * to. Fees are calculated by rounding up the time of day.
   */
  feePerUnitDuration?: Money
  /** The maximum fee of the time period that this pricing is applied to. */
  maxFee?: Money
}

/** A representation of parking's max fee that does not belong to a FeeUnit. */
export interface SubMaxFee {
  /**
   * The hourly max fee is not part of FeeUnit and represents the
   * max fee per time_specified_max_fee_duration in a day.
   * This value is applied when MaxFee in FeeUnit is disabled (or not set)
   * in Itech's pricing system.
   */
  hourly?: HourlyMaxFee | undefined
  /**
   * The daily max fee is max fee per day.
   * This value is applied when MaxFee in FeeUnit is disabled (or not set)
   * and hourly_max_fee is disabled (or not set) in Itech's pricing
   * system.
   */
  daily?: Money | undefined
}

/**
 * A representation of parking's hourly max fee that does not belong to a
 * FeeUnit.
 */
export interface HourlyMaxFee {
  /** The fee per hour is max fee per hour duration. */
  feePerHour?: Money
  /** The hour duration which hourly_max_fee is applied. */
  hourDuration: string
}

const baseParking: object = {
  name: "",
  displayName: "",
  fullAddress: "",
  type: Parking_Type.TYPE_INVALID,
  reservable: false,
  handicappedSpaceNumbers: "",
  isQtNetAvailable: false,
  isSmartPayAvailable: false,
  note: "",
  reservableSpaceNumbers: "",
  parkingGoState: Parking_ParkingGoState.PARKING_GO_STATE_INVALID,
  isMobileCorporateCardAvailable: false,
}

export const Parking = {
  encode(
    message: Parking,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name)
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName)
    }
    if (message.fullAddress !== "") {
      writer.uint32(26).string(message.fullAddress)
    }
    if (message.type !== Parking_Type.TYPE_INVALID) {
      writer.uint32(32).int32(parking_TypeToNumber(message.type))
    }
    if (message.latLng !== undefined) {
      LatLng.encode(message.latLng, writer.uint32(42).fork()).ldelim()
    }
    if (message.availability !== undefined) {
      ParkingAvailability.encode(
        message.availability,
        writer.uint32(50).fork()
      ).ldelim()
    }
    if (message.origin !== undefined) {
      Parking_Origin.encode(message.origin, writer.uint32(58).fork()).ldelim()
    }
    if (message.reservable === true) {
      writer.uint32(64).bool(message.reservable)
    }
    if (message.feeTable !== undefined) {
      FeeTable.encode(message.feeTable, writer.uint32(74).fork()).ldelim()
    }
    for (const v of message.handicappedSpaceNumbers) {
      writer.uint32(82).string(v!)
    }
    if (message.isQtNetAvailable === true) {
      writer.uint32(88).bool(message.isQtNetAvailable)
    }
    if (message.isSmartPayAvailable === true) {
      writer.uint32(96).bool(message.isSmartPayAvailable)
    }
    if (message.note !== "") {
      writer.uint32(106).string(message.note)
    }
    for (const v of message.reservableSpaceNumbers) {
      writer.uint32(114).string(v!)
    }
    if (
      message.parkingGoState !== Parking_ParkingGoState.PARKING_GO_STATE_INVALID
    ) {
      writer
        .uint32(120)
        .int32(parking_ParkingGoStateToNumber(message.parkingGoState))
    }
    if (message.isMobileCorporateCardAvailable === true) {
      writer.uint32(128).bool(message.isMobileCorporateCardAvailable)
    }
    if (message.parkingBusinessOwner !== undefined) {
      ParkingBusinessOwner.encode(
        message.parkingBusinessOwner,
        writer.uint32(146).fork()
      ).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Parking {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseParking } as Parking
    message.handicappedSpaceNumbers = []
    message.reservableSpaceNumbers = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.name = reader.string()
          break
        case 2:
          message.displayName = reader.string()
          break
        case 3:
          message.fullAddress = reader.string()
          break
        case 4:
          message.type = parking_TypeFromJSON(reader.int32())
          break
        case 5:
          message.latLng = LatLng.decode(reader, reader.uint32())
          break
        case 6:
          message.availability = ParkingAvailability.decode(
            reader,
            reader.uint32()
          )
          break
        case 7:
          message.origin = Parking_Origin.decode(reader, reader.uint32())
          break
        case 8:
          message.reservable = reader.bool()
          break
        case 9:
          message.feeTable = FeeTable.decode(reader, reader.uint32())
          break
        case 10:
          message.handicappedSpaceNumbers.push(reader.string())
          break
        case 11:
          message.isQtNetAvailable = reader.bool()
          break
        case 12:
          message.isSmartPayAvailable = reader.bool()
          break
        case 13:
          message.note = reader.string()
          break
        case 14:
          message.reservableSpaceNumbers.push(reader.string())
          break
        case 15:
          message.parkingGoState = parking_ParkingGoStateFromJSON(
            reader.int32()
          )
          break
        case 16:
          message.isMobileCorporateCardAvailable = reader.bool()
          break
        case 18:
          message.parkingBusinessOwner = ParkingBusinessOwner.decode(
            reader,
            reader.uint32()
          )
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Parking {
    const message = { ...baseParking } as Parking
    message.handicappedSpaceNumbers = []
    message.reservableSpaceNumbers = []
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name)
    } else {
      message.name = ""
    }
    if (object.displayName !== undefined && object.displayName !== null) {
      message.displayName = String(object.displayName)
    } else {
      message.displayName = ""
    }
    if (object.fullAddress !== undefined && object.fullAddress !== null) {
      message.fullAddress = String(object.fullAddress)
    } else {
      message.fullAddress = ""
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = parking_TypeFromJSON(object.type)
    } else {
      message.type = Parking_Type.TYPE_INVALID
    }
    if (object.latLng !== undefined && object.latLng !== null) {
      message.latLng = LatLng.fromJSON(object.latLng)
    } else {
      message.latLng = undefined
    }
    if (object.availability !== undefined && object.availability !== null) {
      message.availability = ParkingAvailability.fromJSON(object.availability)
    } else {
      message.availability = undefined
    }
    if (object.origin !== undefined && object.origin !== null) {
      message.origin = Parking_Origin.fromJSON(object.origin)
    } else {
      message.origin = undefined
    }
    if (object.reservable !== undefined && object.reservable !== null) {
      message.reservable = Boolean(object.reservable)
    } else {
      message.reservable = false
    }
    if (object.feeTable !== undefined && object.feeTable !== null) {
      message.feeTable = FeeTable.fromJSON(object.feeTable)
    } else {
      message.feeTable = undefined
    }
    if (
      object.handicappedSpaceNumbers !== undefined &&
      object.handicappedSpaceNumbers !== null
    ) {
      for (const e of object.handicappedSpaceNumbers) {
        message.handicappedSpaceNumbers.push(String(e))
      }
    }
    if (
      object.isQtNetAvailable !== undefined &&
      object.isQtNetAvailable !== null
    ) {
      message.isQtNetAvailable = Boolean(object.isQtNetAvailable)
    } else {
      message.isQtNetAvailable = false
    }
    if (
      object.isSmartPayAvailable !== undefined &&
      object.isSmartPayAvailable !== null
    ) {
      message.isSmartPayAvailable = Boolean(object.isSmartPayAvailable)
    } else {
      message.isSmartPayAvailable = false
    }
    if (object.note !== undefined && object.note !== null) {
      message.note = String(object.note)
    } else {
      message.note = ""
    }
    if (
      object.reservableSpaceNumbers !== undefined &&
      object.reservableSpaceNumbers !== null
    ) {
      for (const e of object.reservableSpaceNumbers) {
        message.reservableSpaceNumbers.push(String(e))
      }
    }
    if (object.parkingGoState !== undefined && object.parkingGoState !== null) {
      message.parkingGoState = parking_ParkingGoStateFromJSON(
        object.parkingGoState
      )
    } else {
      message.parkingGoState = Parking_ParkingGoState.PARKING_GO_STATE_INVALID
    }
    if (
      object.isMobileCorporateCardAvailable !== undefined &&
      object.isMobileCorporateCardAvailable !== null
    ) {
      message.isMobileCorporateCardAvailable = Boolean(
        object.isMobileCorporateCardAvailable
      )
    } else {
      message.isMobileCorporateCardAvailable = false
    }
    if (
      object.parkingBusinessOwner !== undefined &&
      object.parkingBusinessOwner !== null
    ) {
      message.parkingBusinessOwner = ParkingBusinessOwner.fromJSON(
        object.parkingBusinessOwner
      )
    } else {
      message.parkingBusinessOwner = undefined
    }
    return message
  },

  toJSON(message: Parking): unknown {
    const obj: any = {}
    message.name !== undefined && (obj.name = message.name)
    message.displayName !== undefined && (obj.displayName = message.displayName)
    message.fullAddress !== undefined && (obj.fullAddress = message.fullAddress)
    message.type !== undefined && (obj.type = parking_TypeToJSON(message.type))
    message.latLng !== undefined &&
      (obj.latLng = message.latLng ? LatLng.toJSON(message.latLng) : undefined)
    message.availability !== undefined &&
      (obj.availability = message.availability
        ? ParkingAvailability.toJSON(message.availability)
        : undefined)
    message.origin !== undefined &&
      (obj.origin = message.origin
        ? Parking_Origin.toJSON(message.origin)
        : undefined)
    message.reservable !== undefined && (obj.reservable = message.reservable)
    message.feeTable !== undefined &&
      (obj.feeTable = message.feeTable
        ? FeeTable.toJSON(message.feeTable)
        : undefined)
    if (message.handicappedSpaceNumbers) {
      obj.handicappedSpaceNumbers = message.handicappedSpaceNumbers.map(e => e)
    } else {
      obj.handicappedSpaceNumbers = []
    }
    message.isQtNetAvailable !== undefined &&
      (obj.isQtNetAvailable = message.isQtNetAvailable)
    message.isSmartPayAvailable !== undefined &&
      (obj.isSmartPayAvailable = message.isSmartPayAvailable)
    message.note !== undefined && (obj.note = message.note)
    if (message.reservableSpaceNumbers) {
      obj.reservableSpaceNumbers = message.reservableSpaceNumbers.map(e => e)
    } else {
      obj.reservableSpaceNumbers = []
    }
    message.parkingGoState !== undefined &&
      (obj.parkingGoState = parking_ParkingGoStateToJSON(
        message.parkingGoState
      ))
    message.isMobileCorporateCardAvailable !== undefined &&
      (obj.isMobileCorporateCardAvailable =
        message.isMobileCorporateCardAvailable)
    message.parkingBusinessOwner !== undefined &&
      (obj.parkingBusinessOwner = message.parkingBusinessOwner
        ? ParkingBusinessOwner.toJSON(message.parkingBusinessOwner)
        : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<Parking>): Parking {
    const message = { ...baseParking } as Parking
    message.handicappedSpaceNumbers = []
    message.reservableSpaceNumbers = []
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name
    } else {
      message.name = ""
    }
    if (object.displayName !== undefined && object.displayName !== null) {
      message.displayName = object.displayName
    } else {
      message.displayName = ""
    }
    if (object.fullAddress !== undefined && object.fullAddress !== null) {
      message.fullAddress = object.fullAddress
    } else {
      message.fullAddress = ""
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type
    } else {
      message.type = Parking_Type.TYPE_INVALID
    }
    if (object.latLng !== undefined && object.latLng !== null) {
      message.latLng = LatLng.fromPartial(object.latLng)
    } else {
      message.latLng = undefined
    }
    if (object.availability !== undefined && object.availability !== null) {
      message.availability = ParkingAvailability.fromPartial(
        object.availability
      )
    } else {
      message.availability = undefined
    }
    if (object.origin !== undefined && object.origin !== null) {
      message.origin = Parking_Origin.fromPartial(object.origin)
    } else {
      message.origin = undefined
    }
    if (object.reservable !== undefined && object.reservable !== null) {
      message.reservable = object.reservable
    } else {
      message.reservable = false
    }
    if (object.feeTable !== undefined && object.feeTable !== null) {
      message.feeTable = FeeTable.fromPartial(object.feeTable)
    } else {
      message.feeTable = undefined
    }
    if (
      object.handicappedSpaceNumbers !== undefined &&
      object.handicappedSpaceNumbers !== null
    ) {
      for (const e of object.handicappedSpaceNumbers) {
        message.handicappedSpaceNumbers.push(e)
      }
    }
    if (
      object.isQtNetAvailable !== undefined &&
      object.isQtNetAvailable !== null
    ) {
      message.isQtNetAvailable = object.isQtNetAvailable
    } else {
      message.isQtNetAvailable = false
    }
    if (
      object.isSmartPayAvailable !== undefined &&
      object.isSmartPayAvailable !== null
    ) {
      message.isSmartPayAvailable = object.isSmartPayAvailable
    } else {
      message.isSmartPayAvailable = false
    }
    if (object.note !== undefined && object.note !== null) {
      message.note = object.note
    } else {
      message.note = ""
    }
    if (
      object.reservableSpaceNumbers !== undefined &&
      object.reservableSpaceNumbers !== null
    ) {
      for (const e of object.reservableSpaceNumbers) {
        message.reservableSpaceNumbers.push(e)
      }
    }
    if (object.parkingGoState !== undefined && object.parkingGoState !== null) {
      message.parkingGoState = object.parkingGoState
    } else {
      message.parkingGoState = Parking_ParkingGoState.PARKING_GO_STATE_INVALID
    }
    if (
      object.isMobileCorporateCardAvailable !== undefined &&
      object.isMobileCorporateCardAvailable !== null
    ) {
      message.isMobileCorporateCardAvailable =
        object.isMobileCorporateCardAvailable
    } else {
      message.isMobileCorporateCardAvailable = false
    }
    if (
      object.parkingBusinessOwner !== undefined &&
      object.parkingBusinessOwner !== null
    ) {
      message.parkingBusinessOwner = ParkingBusinessOwner.fromPartial(
        object.parkingBusinessOwner
      )
    } else {
      message.parkingBusinessOwner = undefined
    }
    return message
  },
}

const baseParking_Origin: object = { supplier: "", rawId: "" }

export const Parking_Origin = {
  encode(
    message: Parking_Origin,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.supplier !== "") {
      writer.uint32(10).string(message.supplier)
    }
    if (message.rawId !== "") {
      writer.uint32(18).string(message.rawId)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Parking_Origin {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseParking_Origin } as Parking_Origin
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.supplier = reader.string()
          break
        case 2:
          message.rawId = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): Parking_Origin {
    const message = { ...baseParking_Origin } as Parking_Origin
    if (object.supplier !== undefined && object.supplier !== null) {
      message.supplier = String(object.supplier)
    } else {
      message.supplier = ""
    }
    if (object.rawId !== undefined && object.rawId !== null) {
      message.rawId = String(object.rawId)
    } else {
      message.rawId = ""
    }
    return message
  },

  toJSON(message: Parking_Origin): unknown {
    const obj: any = {}
    message.supplier !== undefined && (obj.supplier = message.supplier)
    message.rawId !== undefined && (obj.rawId = message.rawId)
    return obj
  },

  fromPartial(object: DeepPartial<Parking_Origin>): Parking_Origin {
    const message = { ...baseParking_Origin } as Parking_Origin
    if (object.supplier !== undefined && object.supplier !== null) {
      message.supplier = object.supplier
    } else {
      message.supplier = ""
    }
    if (object.rawId !== undefined && object.rawId !== null) {
      message.rawId = object.rawId
    } else {
      message.rawId = ""
    }
    return message
  },
}

const baseParkingAvailability: object = {
  totalSpaceCount: 0,
  inUseSpaceCount: 0,
  summary: ParkingAvailability_Summary.SUMMARY_INVALID,
}

export const ParkingAvailability = {
  encode(
    message: ParkingAvailability,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.totalSpaceCount !== 0) {
      writer.uint32(8).int32(message.totalSpaceCount)
    }
    if (message.inUseSpaceCount !== 0) {
      writer.uint32(16).int32(message.inUseSpaceCount)
    }
    if (message.summary !== ParkingAvailability_Summary.SUMMARY_INVALID) {
      writer
        .uint32(24)
        .int32(parkingAvailability_SummaryToNumber(message.summary))
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ParkingAvailability {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseParkingAvailability } as ParkingAvailability
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.totalSpaceCount = reader.int32()
          break
        case 2:
          message.inUseSpaceCount = reader.int32()
          break
        case 3:
          message.summary = parkingAvailability_SummaryFromJSON(reader.int32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): ParkingAvailability {
    const message = { ...baseParkingAvailability } as ParkingAvailability
    if (
      object.totalSpaceCount !== undefined &&
      object.totalSpaceCount !== null
    ) {
      message.totalSpaceCount = Number(object.totalSpaceCount)
    } else {
      message.totalSpaceCount = 0
    }
    if (
      object.inUseSpaceCount !== undefined &&
      object.inUseSpaceCount !== null
    ) {
      message.inUseSpaceCount = Number(object.inUseSpaceCount)
    } else {
      message.inUseSpaceCount = 0
    }
    if (object.summary !== undefined && object.summary !== null) {
      message.summary = parkingAvailability_SummaryFromJSON(object.summary)
    } else {
      message.summary = ParkingAvailability_Summary.SUMMARY_INVALID
    }
    return message
  },

  toJSON(message: ParkingAvailability): unknown {
    const obj: any = {}
    message.totalSpaceCount !== undefined &&
      (obj.totalSpaceCount = message.totalSpaceCount)
    message.inUseSpaceCount !== undefined &&
      (obj.inUseSpaceCount = message.inUseSpaceCount)
    message.summary !== undefined &&
      (obj.summary = parkingAvailability_SummaryToJSON(message.summary))
    return obj
  },

  fromPartial(object: DeepPartial<ParkingAvailability>): ParkingAvailability {
    const message = { ...baseParkingAvailability } as ParkingAvailability
    if (
      object.totalSpaceCount !== undefined &&
      object.totalSpaceCount !== null
    ) {
      message.totalSpaceCount = object.totalSpaceCount
    } else {
      message.totalSpaceCount = 0
    }
    if (
      object.inUseSpaceCount !== undefined &&
      object.inUseSpaceCount !== null
    ) {
      message.inUseSpaceCount = object.inUseSpaceCount
    } else {
      message.inUseSpaceCount = 0
    }
    if (object.summary !== undefined && object.summary !== null) {
      message.summary = object.summary
    } else {
      message.summary = ParkingAvailability_Summary.SUMMARY_INVALID
    }
    return message
  },
}

const baseFeeTable: object = {}

export const FeeTable = {
  encode(
    message: FeeTable,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    for (const v of message.weekdayFees) {
      FeeUnit.encode(v!, writer.uint32(10).fork()).ldelim()
    }
    for (const v of message.saturdayFees) {
      FeeUnit.encode(v!, writer.uint32(18).fork()).ldelim()
    }
    for (const v of message.sundayFees) {
      FeeUnit.encode(v!, writer.uint32(26).fork()).ldelim()
    }
    for (const v of message.holidayFees) {
      FeeUnit.encode(v!, writer.uint32(34).fork()).ldelim()
    }
    if (message.weekdayMaxFee !== undefined) {
      SubMaxFee.encode(message.weekdayMaxFee, writer.uint32(42).fork()).ldelim()
    }
    if (message.saturdayMaxFee !== undefined) {
      SubMaxFee.encode(
        message.saturdayMaxFee,
        writer.uint32(50).fork()
      ).ldelim()
    }
    if (message.sundayMaxFee !== undefined) {
      SubMaxFee.encode(message.sundayMaxFee, writer.uint32(58).fork()).ldelim()
    }
    if (message.holidayMaxFee !== undefined) {
      SubMaxFee.encode(message.holidayMaxFee, writer.uint32(66).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeeTable {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseFeeTable } as FeeTable
    message.weekdayFees = []
    message.saturdayFees = []
    message.sundayFees = []
    message.holidayFees = []
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.weekdayFees.push(FeeUnit.decode(reader, reader.uint32()))
          break
        case 2:
          message.saturdayFees.push(FeeUnit.decode(reader, reader.uint32()))
          break
        case 3:
          message.sundayFees.push(FeeUnit.decode(reader, reader.uint32()))
          break
        case 4:
          message.holidayFees.push(FeeUnit.decode(reader, reader.uint32()))
          break
        case 5:
          message.weekdayMaxFee = SubMaxFee.decode(reader, reader.uint32())
          break
        case 6:
          message.saturdayMaxFee = SubMaxFee.decode(reader, reader.uint32())
          break
        case 7:
          message.sundayMaxFee = SubMaxFee.decode(reader, reader.uint32())
          break
        case 8:
          message.holidayMaxFee = SubMaxFee.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): FeeTable {
    const message = { ...baseFeeTable } as FeeTable
    message.weekdayFees = []
    message.saturdayFees = []
    message.sundayFees = []
    message.holidayFees = []
    if (object.weekdayFees !== undefined && object.weekdayFees !== null) {
      for (const e of object.weekdayFees) {
        message.weekdayFees.push(FeeUnit.fromJSON(e))
      }
    }
    if (object.saturdayFees !== undefined && object.saturdayFees !== null) {
      for (const e of object.saturdayFees) {
        message.saturdayFees.push(FeeUnit.fromJSON(e))
      }
    }
    if (object.sundayFees !== undefined && object.sundayFees !== null) {
      for (const e of object.sundayFees) {
        message.sundayFees.push(FeeUnit.fromJSON(e))
      }
    }
    if (object.holidayFees !== undefined && object.holidayFees !== null) {
      for (const e of object.holidayFees) {
        message.holidayFees.push(FeeUnit.fromJSON(e))
      }
    }
    if (object.weekdayMaxFee !== undefined && object.weekdayMaxFee !== null) {
      message.weekdayMaxFee = SubMaxFee.fromJSON(object.weekdayMaxFee)
    } else {
      message.weekdayMaxFee = undefined
    }
    if (object.saturdayMaxFee !== undefined && object.saturdayMaxFee !== null) {
      message.saturdayMaxFee = SubMaxFee.fromJSON(object.saturdayMaxFee)
    } else {
      message.saturdayMaxFee = undefined
    }
    if (object.sundayMaxFee !== undefined && object.sundayMaxFee !== null) {
      message.sundayMaxFee = SubMaxFee.fromJSON(object.sundayMaxFee)
    } else {
      message.sundayMaxFee = undefined
    }
    if (object.holidayMaxFee !== undefined && object.holidayMaxFee !== null) {
      message.holidayMaxFee = SubMaxFee.fromJSON(object.holidayMaxFee)
    } else {
      message.holidayMaxFee = undefined
    }
    return message
  },

  toJSON(message: FeeTable): unknown {
    const obj: any = {}
    if (message.weekdayFees) {
      obj.weekdayFees = message.weekdayFees.map(e =>
        e ? FeeUnit.toJSON(e) : undefined
      )
    } else {
      obj.weekdayFees = []
    }
    if (message.saturdayFees) {
      obj.saturdayFees = message.saturdayFees.map(e =>
        e ? FeeUnit.toJSON(e) : undefined
      )
    } else {
      obj.saturdayFees = []
    }
    if (message.sundayFees) {
      obj.sundayFees = message.sundayFees.map(e =>
        e ? FeeUnit.toJSON(e) : undefined
      )
    } else {
      obj.sundayFees = []
    }
    if (message.holidayFees) {
      obj.holidayFees = message.holidayFees.map(e =>
        e ? FeeUnit.toJSON(e) : undefined
      )
    } else {
      obj.holidayFees = []
    }
    message.weekdayMaxFee !== undefined &&
      (obj.weekdayMaxFee = message.weekdayMaxFee
        ? SubMaxFee.toJSON(message.weekdayMaxFee)
        : undefined)
    message.saturdayMaxFee !== undefined &&
      (obj.saturdayMaxFee = message.saturdayMaxFee
        ? SubMaxFee.toJSON(message.saturdayMaxFee)
        : undefined)
    message.sundayMaxFee !== undefined &&
      (obj.sundayMaxFee = message.sundayMaxFee
        ? SubMaxFee.toJSON(message.sundayMaxFee)
        : undefined)
    message.holidayMaxFee !== undefined &&
      (obj.holidayMaxFee = message.holidayMaxFee
        ? SubMaxFee.toJSON(message.holidayMaxFee)
        : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<FeeTable>): FeeTable {
    const message = { ...baseFeeTable } as FeeTable
    message.weekdayFees = []
    message.saturdayFees = []
    message.sundayFees = []
    message.holidayFees = []
    if (object.weekdayFees !== undefined && object.weekdayFees !== null) {
      for (const e of object.weekdayFees) {
        message.weekdayFees.push(FeeUnit.fromPartial(e))
      }
    }
    if (object.saturdayFees !== undefined && object.saturdayFees !== null) {
      for (const e of object.saturdayFees) {
        message.saturdayFees.push(FeeUnit.fromPartial(e))
      }
    }
    if (object.sundayFees !== undefined && object.sundayFees !== null) {
      for (const e of object.sundayFees) {
        message.sundayFees.push(FeeUnit.fromPartial(e))
      }
    }
    if (object.holidayFees !== undefined && object.holidayFees !== null) {
      for (const e of object.holidayFees) {
        message.holidayFees.push(FeeUnit.fromPartial(e))
      }
    }
    if (object.weekdayMaxFee !== undefined && object.weekdayMaxFee !== null) {
      message.weekdayMaxFee = SubMaxFee.fromPartial(object.weekdayMaxFee)
    } else {
      message.weekdayMaxFee = undefined
    }
    if (object.saturdayMaxFee !== undefined && object.saturdayMaxFee !== null) {
      message.saturdayMaxFee = SubMaxFee.fromPartial(object.saturdayMaxFee)
    } else {
      message.saturdayMaxFee = undefined
    }
    if (object.sundayMaxFee !== undefined && object.sundayMaxFee !== null) {
      message.sundayMaxFee = SubMaxFee.fromPartial(object.sundayMaxFee)
    } else {
      message.sundayMaxFee = undefined
    }
    if (object.holidayMaxFee !== undefined && object.holidayMaxFee !== null) {
      message.holidayMaxFee = SubMaxFee.fromPartial(object.holidayMaxFee)
    } else {
      message.holidayMaxFee = undefined
    }
    return message
  },
}

const baseFeeUnit: object = { unitDuration: "" }

export const FeeUnit = {
  encode(
    message: FeeUnit,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.startTime !== undefined) {
      TimeOfDay.encode(message.startTime, writer.uint32(10).fork()).ldelim()
    }
    if (message.endTime !== undefined) {
      TimeOfDay.encode(message.endTime, writer.uint32(18).fork()).ldelim()
    }
    if (message.unitDuration !== "") {
      writer.uint32(26).string(message.unitDuration)
    }
    if (message.feePerUnitDuration !== undefined) {
      Money.encode(
        message.feePerUnitDuration,
        writer.uint32(34).fork()
      ).ldelim()
    }
    if (message.maxFee !== undefined) {
      Money.encode(message.maxFee, writer.uint32(42).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeeUnit {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseFeeUnit } as FeeUnit
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.startTime = TimeOfDay.decode(reader, reader.uint32())
          break
        case 2:
          message.endTime = TimeOfDay.decode(reader, reader.uint32())
          break
        case 3:
          message.unitDuration = reader.string()
          break
        case 4:
          message.feePerUnitDuration = Money.decode(reader, reader.uint32())
          break
        case 5:
          message.maxFee = Money.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): FeeUnit {
    const message = { ...baseFeeUnit } as FeeUnit
    if (object.startTime !== undefined && object.startTime !== null) {
      message.startTime = TimeOfDay.fromJSON(object.startTime)
    } else {
      message.startTime = undefined
    }
    if (object.endTime !== undefined && object.endTime !== null) {
      message.endTime = TimeOfDay.fromJSON(object.endTime)
    } else {
      message.endTime = undefined
    }
    if (object.unitDuration !== undefined && object.unitDuration !== null) {
      message.unitDuration = String(object.unitDuration)
    } else {
      message.unitDuration = ""
    }
    if (
      object.feePerUnitDuration !== undefined &&
      object.feePerUnitDuration !== null
    ) {
      message.feePerUnitDuration = Money.fromJSON(object.feePerUnitDuration)
    } else {
      message.feePerUnitDuration = undefined
    }
    if (object.maxFee !== undefined && object.maxFee !== null) {
      message.maxFee = Money.fromJSON(object.maxFee)
    } else {
      message.maxFee = undefined
    }
    return message
  },

  toJSON(message: FeeUnit): unknown {
    const obj: any = {}
    message.startTime !== undefined &&
      (obj.startTime = message.startTime
        ? TimeOfDay.toJSON(message.startTime)
        : undefined)
    message.endTime !== undefined &&
      (obj.endTime = message.endTime
        ? TimeOfDay.toJSON(message.endTime)
        : undefined)
    message.unitDuration !== undefined &&
      (obj.unitDuration = message.unitDuration)
    message.feePerUnitDuration !== undefined &&
      (obj.feePerUnitDuration = message.feePerUnitDuration
        ? Money.toJSON(message.feePerUnitDuration)
        : undefined)
    message.maxFee !== undefined &&
      (obj.maxFee = message.maxFee ? Money.toJSON(message.maxFee) : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<FeeUnit>): FeeUnit {
    const message = { ...baseFeeUnit } as FeeUnit
    if (object.startTime !== undefined && object.startTime !== null) {
      message.startTime = TimeOfDay.fromPartial(object.startTime)
    } else {
      message.startTime = undefined
    }
    if (object.endTime !== undefined && object.endTime !== null) {
      message.endTime = TimeOfDay.fromPartial(object.endTime)
    } else {
      message.endTime = undefined
    }
    if (object.unitDuration !== undefined && object.unitDuration !== null) {
      message.unitDuration = object.unitDuration
    } else {
      message.unitDuration = ""
    }
    if (
      object.feePerUnitDuration !== undefined &&
      object.feePerUnitDuration !== null
    ) {
      message.feePerUnitDuration = Money.fromPartial(object.feePerUnitDuration)
    } else {
      message.feePerUnitDuration = undefined
    }
    if (object.maxFee !== undefined && object.maxFee !== null) {
      message.maxFee = Money.fromPartial(object.maxFee)
    } else {
      message.maxFee = undefined
    }
    return message
  },
}

const baseSubMaxFee: object = {}

export const SubMaxFee = {
  encode(
    message: SubMaxFee,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.hourly !== undefined) {
      HourlyMaxFee.encode(message.hourly, writer.uint32(10).fork()).ldelim()
    }
    if (message.daily !== undefined) {
      Money.encode(message.daily, writer.uint32(18).fork()).ldelim()
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubMaxFee {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseSubMaxFee } as SubMaxFee
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.hourly = HourlyMaxFee.decode(reader, reader.uint32())
          break
        case 2:
          message.daily = Money.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): SubMaxFee {
    const message = { ...baseSubMaxFee } as SubMaxFee
    if (object.hourly !== undefined && object.hourly !== null) {
      message.hourly = HourlyMaxFee.fromJSON(object.hourly)
    } else {
      message.hourly = undefined
    }
    if (object.daily !== undefined && object.daily !== null) {
      message.daily = Money.fromJSON(object.daily)
    } else {
      message.daily = undefined
    }
    return message
  },

  toJSON(message: SubMaxFee): unknown {
    const obj: any = {}
    message.hourly !== undefined &&
      (obj.hourly = message.hourly
        ? HourlyMaxFee.toJSON(message.hourly)
        : undefined)
    message.daily !== undefined &&
      (obj.daily = message.daily ? Money.toJSON(message.daily) : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<SubMaxFee>): SubMaxFee {
    const message = { ...baseSubMaxFee } as SubMaxFee
    if (object.hourly !== undefined && object.hourly !== null) {
      message.hourly = HourlyMaxFee.fromPartial(object.hourly)
    } else {
      message.hourly = undefined
    }
    if (object.daily !== undefined && object.daily !== null) {
      message.daily = Money.fromPartial(object.daily)
    } else {
      message.daily = undefined
    }
    return message
  },
}

const baseHourlyMaxFee: object = { hourDuration: "" }

export const HourlyMaxFee = {
  encode(
    message: HourlyMaxFee,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.feePerHour !== undefined) {
      Money.encode(message.feePerHour, writer.uint32(10).fork()).ldelim()
    }
    if (message.hourDuration !== "") {
      writer.uint32(18).string(message.hourDuration)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HourlyMaxFee {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseHourlyMaxFee } as HourlyMaxFee
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.feePerHour = Money.decode(reader, reader.uint32())
          break
        case 2:
          message.hourDuration = reader.string()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): HourlyMaxFee {
    const message = { ...baseHourlyMaxFee } as HourlyMaxFee
    if (object.feePerHour !== undefined && object.feePerHour !== null) {
      message.feePerHour = Money.fromJSON(object.feePerHour)
    } else {
      message.feePerHour = undefined
    }
    if (object.hourDuration !== undefined && object.hourDuration !== null) {
      message.hourDuration = String(object.hourDuration)
    } else {
      message.hourDuration = ""
    }
    return message
  },

  toJSON(message: HourlyMaxFee): unknown {
    const obj: any = {}
    message.feePerHour !== undefined &&
      (obj.feePerHour = message.feePerHour
        ? Money.toJSON(message.feePerHour)
        : undefined)
    message.hourDuration !== undefined &&
      (obj.hourDuration = message.hourDuration)
    return obj
  },

  fromPartial(object: DeepPartial<HourlyMaxFee>): HourlyMaxFee {
    const message = { ...baseHourlyMaxFee } as HourlyMaxFee
    if (object.feePerHour !== undefined && object.feePerHour !== null) {
      message.feePerHour = Money.fromPartial(object.feePerHour)
    } else {
      message.feePerHour = undefined
    }
    if (object.hourDuration !== undefined && object.hourDuration !== null) {
      message.hourDuration = object.hourDuration
    } else {
      message.hourDuration = ""
    }
    return message
  },
}

declare var self: any | undefined
declare var window: any | undefined
declare var global: any | undefined
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis
  if (typeof self !== "undefined") return self
  if (typeof window !== "undefined") return window
  if (typeof global !== "undefined") return global
  throw "Unable to locate global object"
})()

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any
  _m0.configure()
}
