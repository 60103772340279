// extracted by mini-css-extract-plugin
export var header = "header-module--header--rokHo";
export var row = "header-module--row--eEzR-";
export var commonTextStyle = "header-module--commonTextStyle--mY_Lc";
export var userInfo = "header-module--userInfo--5gBYR";
export var menuItem = "header-module--menuItem--2XM0d";
export var serviceName = "header-module--serviceName--1jTtG";
export var title = "header-module--title--h9tnr";
export var logo = "header-module--logo--1pBRd";
export var menu = "header-module--menu--24__W";
export var userSection = "header-module--userSection--2Ccn_";
export var dropdownMenu = "header-module--dropdownMenu--1Xqjc";
export var dropdownItem = "header-module--dropdownItem--2ScAG";