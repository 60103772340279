import { Link } from "gatsby"
import React, { ReactElement } from "react"
import { LatLng } from "../../__generated__/proto/google/type/latlng"
import {
  Parking,
  ParkingAvailability_Summary,
  Parking_Type,
} from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import {
  FeeUnitExtension,
  HourlyMaxFeeExtension,
  SubMaxFeeExtension,
} from "../../domain/parking-extensions"
import {
  GetDistanceUseCase,
  GetParkingCurrentFeeUseCase,
  GetParkingCurrentSubMaxFeeUseCase,
} from "../../domain/use-cases"
import * as styles from "./search-result-list-item.module.scss"

interface Props {
  parking: Parking
  mapCenter: LatLng | null
  onClick: (parking: Parking) => void
  onClickName: (parking: Parking) => void
}

export const SearchResultListItem = React.memo((props: Props): ReactElement => {
  // MARK: - UseCases

  const getDistanceUseCase = new GetDistanceUseCase()
  const getCurrentFeeUseCase = new GetParkingCurrentFeeUseCase()
  const getCurrentSubMaxFeeUseCase = new GetParkingCurrentSubMaxFeeUseCase()

  // MARK: - Components

  const statusItem = (
    availability?: ParkingAvailability_Summary
  ): ReactElement => {
    let className: string
    let title: string
    switch (availability) {
      case undefined:
      case ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE:
      case ParkingAvailability_Summary.SUMMARY_INVALID:
        className = styles.invalidStatusItem
        title = "不明"
        break
      case ParkingAvailability_Summary.SUMMARY_EMPTY:
        className = styles.emptyStatusItem
        title = "空車"
        break
      case ParkingAvailability_Summary.SUMMARY_CROWDED:
        className = styles.crowdedStatusItem
        title = "混雑"
        break
      case ParkingAvailability_Summary.SUMMARY_FULL:
        className = styles.fullStatusItem
        title = "満車"
        break
    }
    return (
      <div className={className}>
        <p className={styles.statusItemText}>{title}</p>
      </div>
    )
  }

  const serviceIcons = (): ReactElement => {
    return (
      <div className={styles.serviceIcons}>
        {props.parking.isQtNetAvailable && (
          <img src="/tag-QT.webp" width={22} height={16} alt="tag-QT" />
        )}
        {props.parking.handicappedSpaceNumbers.length > 0 && (
          <img
            src="/tag-wheelchair.webp"
            width={22}
            height={16}
            alt="tag-wheelchair"
          />
        )}
        {props.parking.type === Parking_Type.TYPE_LOCKLESS && (
          <img
            src="/tag-lockless.webp"
            width={46}
            height={16}
            alt="tag-lockless"
          />
        )}
        {/* TODO: Add <img src="/tag-credit.webp" /> */}
        {/* TODO: Add <img src="/tag-emonry.webp" /> */}
      </div>
    )
  }

  // MARK: - Render

  const now = new Date()

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.container}
        onClick={() => props.onClick(props.parking)}
      >
        <Link
          to={`/${props.parking.name}`}
          onClick={e => {
            e.preventDefault()
            props.onClickName(props.parking)
          }}
          className={styles.parkingNameLink}
        >
          {props.parking.displayName}
        </Link>
        {statusItem(props.parking.availability?.summary)}
        <dl className={styles.infoTable}>
          <dt>
            <img src={"/location.webp"} alt="location" width={16} height={16} />
            <p>距離</p>
          </dt>
          <dd>{distanceFromMapCenter()}</dd>
          <dt>
            <img src={"/car.webp"} alt="car" width={16} height={16} />
            <p>車室数</p>
          </dt>
          <dd>{totalSpaceCount()}</dd>
          <dt>
            <img src={"/price.webp"} alt="price" width={16} height={16} />
            <p>現在料金</p>
          </dt>
          <dd>{currentFee(props.parking, now)}</dd>
          <dt>
            <img src={"/price.webp"} alt="price" width={16} height={16} />
            <p>現在最大料金</p>
          </dt>
          <dd>{currentMaxFee(props.parking, now)}</dd>
          <dt>
            <img src={"/service.webp"} alt="service" width={16} height={16} />
            <p>対応サービス</p>
          </dt>
          <dd>{serviceIcons()}</dd>
        </dl>
      </div>
      <div className={styles.bottomBorder} />
    </div>
  )

  // MARK: - Methods

  function distanceFromMapCenter(): string {
    const mapCenter = props.mapCenter
    const latLng = props.parking.latLng
    if (mapCenter == null || latLng == null) {
      return "不明"
    }
    const distance = getDistanceUseCase.call({
      from: mapCenter,
      to: latLng,
      units: "meters",
    })
    return `地図の中心から${Math.round(distance)}m`
  }

  function totalSpaceCount(): string {
    const availability = props.parking.availability
    if (
      availability == null ||
      availability.summary == ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE
    ) {
      return "不明"
    }
    return `${availability.totalSpaceCount}車室`
  }

  function currentFee(parking: Parking, now: Date): string {
    const feeUnit = getCurrentFeeUseCase.call({ parking: parking, date: now })
    if (!feeUnit) {
      return "不明"
    }

    const yen = FeeUnitExtension.wrappedFeePerUnitDuration(feeUnit)
    const unitMinutes = FeeUnitExtension.wrappedUnitDurationInMinutes(feeUnit)
    if (!yen || !unitMinutes) {
      return "不明"
    }

    return `${yen}円/${unitMinutes}分`
  }

  function currentMaxFee(parking: Parking, now: Date): string {
    const feeUnit = getCurrentFeeUseCase.call({ parking: parking, date: now })
    const subMaxFee = getCurrentSubMaxFeeUseCase.call({
      parking: parking,
      date: now,
    })
    if (!feeUnit || !subMaxFee) {
      return "不明"
    }

    if (FeeUnitExtension.isValidFeeUnitMaxFee(feeUnit)) {
      const yen = FeeUnitExtension.wrappedMaxFee(feeUnit)
      if (!yen) {
        return "不明"
      }
      return `時間帯最大料金 ${yen}円`
    }

    if (subMaxFee.hourly) {
      const unitMinutes = HourlyMaxFeeExtension.wrappedHourDurationInHours(
        subMaxFee.hourly
      )
      const yen = HourlyMaxFeeExtension.wrappedFeePerHour(subMaxFee.hourly)
      if (!unitMinutes || !yen) {
        return "不明"
      }
      return `${unitMinutes}時間以内最大料金 ${yen}円`
    }

    if (subMaxFee.daily) {
      const yen = SubMaxFeeExtension.wrappedDaily(subMaxFee)
      if (!yen) {
        return "不明"
      }
      return `当日最大料金 ${yen}円`
    }

    return "不明"
  }
})
