import { UseCase } from "./use-case"

export type Region = Map<string, string[]>

export class GetRegionsUseCase implements UseCase<void, Region> {
  call(): Region {
    return new Map<string, string[]>([
      [
        "北海道・東北",
        ["北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県"],
      ],
      [
        "関東",
        [
          "東京都",
          "神奈川県",
          "埼玉県",
          "千葉県",
          "茨城県",
          "栃木県",
          "群馬県",
        ],
      ],
      [
        "北陸・甲信越",
        ["山梨県", "長野県", "新潟県", "富山県", "石川県", "福井県"],
      ],
      ["東海", ["愛知県", "静岡県", "岐阜県", "三重県"]],
      ["関西", ["大阪府", "兵庫県", "京都府", "滋賀県", "奈良県", "和歌山県"]],
      ["中国", ["岡山県", "広島県", "鳥取県", "島根県", "山口県"]],
      ["四国", ["徳島県", "香川県", "愛媛県", "高知県"]],
      [
        "九州・沖縄",
        [
          "福岡県",
          "佐賀県",
          "長崎県",
          "熊本県",
          "大分県",
          "宮崎県",
          "鹿児島県",
          "沖縄県",
        ],
      ],
    ])
  }
}
