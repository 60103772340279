import {
  Parking,
  ParkingAvailability_Summary,
} from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { SearchFilterType } from "../../types"
import { UseCase } from "./use-case"

interface Props {
  parkings: Parking[]
  types: (keyof typeof SearchFilterType)[]
}

export class FilterParkingsUseCase implements UseCase<Props, Parking[]> {
  call({ parkings, types }: Props): Parking[] {
    let newParkings = [...parkings]
    types.forEach(type => {
      switch (type) {
        case "AVAILABLE":
          newParkings = newParkings.filter(parking => {
            const summary = parking.availability?.summary
            return (
              summary === ParkingAvailability_Summary.SUMMARY_EMPTY ||
              summary === ParkingAvailability_Summary.SUMMARY_CROWDED
            )
          })
          break
        case "WHEELCHAIR":
          newParkings = newParkings.filter(
            parking => parking.handicappedSpaceNumbers.length > 0
          )
          break
        case "QT_NET_RESEVABLE":
          newParkings = newParkings.filter(
            parking => parking.isQtNetAvailable && parking.reservable
          )
          break
        case "QT_NET_POINT":
          newParkings = newParkings.filter(parking => parking.isQtNetAvailable)
          break
        case "QT_NET_SMART_PAY":
          newParkings = newParkings.filter(
            parking => parking.isQtNetAvailable && parking.isSmartPayAvailable
          )
          break
      }
    })
    return newParkings
  }
}
