import { Viewport } from "../../__generated__/proto/google/geo/type/viewport"
import { ListParkingsResponse } from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import { ParkingServiceType } from "../services"
import { UseCase } from "./use-case"

interface Props {
  pageSize: number
  pageToken?: string
  boundingBox?: Viewport
}

export class ListParkingsUseCase
  implements UseCase<Props, Promise<ListParkingsResponse>>
{
  constructor(readonly parkingService: ParkingServiceType) {}

  async call(props: Props): Promise<ListParkingsResponse> {
    const result = await this.parkingService.listParkings({
      pageSize: props.pageSize,
      pageToken: props.pageToken ?? "",
      boundingBox: props.boundingBox,
    })
    if (result.isFailure()) {
      console.error(result.value)
      return {
        nextPageToken: "",
        parkings: [],
      }
    }
    return result.value
  }
}
