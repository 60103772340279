import React, { ReactElement, useRef, useState } from "react"
import { SearchResultSortMenu } from ".."
import { PARKINGS_COUNT_PER_PAGE } from "../../config"
import { SearchOrderType } from "../../types"
import * as styles from "./search-result-paging-row.module.scss"

interface Props {
  allParkingCount: number
  currentPage: number
  orderType: keyof typeof SearchOrderType
  showOrderSelection: boolean
  onSelectPage: (page: number) => void
  onSelectOrder?: (orderType: keyof typeof SearchOrderType) => void
}

export const SearchResultPagingRow = (props: Props): ReactElement => {
  // MARK: - Variables

  const pages = Math.ceil(props.allParkingCount / PARKINGS_COUNT_PER_PAGE)
  const sortMenuButtonRef = useRef<HTMLAnchorElement>(null)

  // MARK: - States

  const [showingSortMenu, setShowingSortMenu] = useState(false)

  // MARK: - Components

  const pageLinkText = (page: number): ReactElement => {
    const disabled = page === props.currentPage
    return (
      <a
        key={page}
        className={disabled ? styles.disabledPageLinkText : styles.pageLinkText}
        onClick={e => {
          e.preventDefault()
          if (!disabled) {
            props.onSelectPage(page)
          }
        }}
      >
        {page}
      </a>
    )
  }

  // MARK: - Methods

  const showSortMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowingSortMenu(true)
    document.addEventListener("click", closeSortMenu)
  }

  const closeSortMenu = (e: MouseEvent) => {
    if (
      showingSortMenu ||
      !sortMenuButtonRef.current?.contains(e.target as Node)
    ) {
      setShowingSortMenu(false)
      document.removeEventListener("click", closeSortMenu)
    }
  }

  // MARK: - React Element

  return (
    <div className={styles.parkingListHeader}>
      <div>
        <p className={styles.displayingItemsCountText}>
          {props.allParkingCount === 0
            ? "0件表示"
            : `${
                PARKINGS_COUNT_PER_PAGE * (props.currentPage - 1) + 1
              }~${Math.min(
                PARKINGS_COUNT_PER_PAGE * props.currentPage,
                props.allParkingCount
              )}件表示/全${props.allParkingCount}件`}
        </p>
        <div className={styles.pageLinkRow}>
          {pages === 0
            ? pageLinkText(1)
            : [...range(1, pages + 1)].map(pageLinkText)}
        </div>
      </div>
      <div style={{ flex: 1 }} />
      {props.showOrderSelection && (
        <div>
          <a
            ref={sortMenuButtonRef}
            className={styles.sortMenuButton}
            onClick={e => showSortMenu(e)}
          >
            {SearchOrderType[props.orderType]}
          </a>
          {showingSortMenu && (
            <SearchResultSortMenu
              currentOrderType={props.orderType}
              onSelectOrderType={type => {
                props.onSelectOrder ? props.onSelectOrder(type) : null
                setShowingSortMenu(false)
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}

// MARK: - Methods

function* range(start: number, end: number) {
  for (let i = start; i < end; i++) {
    yield i
  }
}
