import distance from "@turf/distance"
import { point, Units } from "@turf/helpers"
import { LatLng } from "../../__generated__/proto/google/type/latlng"
import { UseCase } from "./use-case"

interface Props {
  from: LatLng
  to: LatLng
  units: Units
}

export class GetDistanceUseCase implements UseCase<Props, number> {
  call({ from, to, units }: Props): number {
    return distance(
      point([from.longitude, from.latitude]),
      point([to.longitude, to.latitude]),
      { units: units }
    )
  }
}
