// Design: https://www.figma.com/design/yPi4YLfFUMYDa0o0902qKj/ITC_QT-net-%E9%A7%90%E8%BB%8A%E5%A0%B4%E6%A4%9C%E7%B4%A2?node-id=1664-9184&t=3V325GrRr0U1qoGe-4
import classNames from "classnames"
import React, {
  PropsWithChildren,
  MouseEventHandler,
  forwardRef,
  ButtonHTMLAttributes,
  Ref,
} from "react"
import * as styles from "./button.module.scss"

type VariantType = "primary" | "secondary" | "danger"
type Shape = "square" | "rounded"
type LayoutType = "hug" | "fill"

type ButtonBaseProps = {
  variant?: VariantType
  shape?: Shape
  layout?: LayoutType
  disabled?: boolean
  onClick?: MouseEventHandler
}

type ButtonProps = PropsWithChildren<ButtonBaseProps> &
  ButtonHTMLAttributes<HTMLButtonElement>

export const Button = forwardRef(
  (
    {
      children,
      variant = "primary",
      shape = "square",
      layout = "hug",
      disabled = false,
      onClick,
      ...rest
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <button
        className={classNames([
          styles.button,
          styles[variant],
          styles[shape],
          styles[layout],
        ])}
        disabled={disabled}
        aria-disabled={disabled}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        <div className="relative">{children}</div>
      </button>
    )
  }
)

Button.displayName = "Button"
