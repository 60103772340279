import React, { ReactElement } from "react"

import * as styles from "./parking-detail-info-row.module.scss"
export const DefaultInfoRow = ({
  column,
  value,
}: {
  column: string
  value: string
}): ReactElement => (
  <div className={styles.infoRow}>
    <div className={styles.infoKeySection}>{column}</div>
    <div className={styles.infoValueSection}>{value}</div>
  </div>
)
