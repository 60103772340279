import { Link } from "gatsby"
import React, { ReactElement } from "react"
import * as styles from "./city-selection-view.module.scss"

interface Props {
  prefecture: string
  cities: string[]
}

export const CitySelectionView = ({
  prefecture,
  cities,
}: Props): ReactElement => (
  <div className={styles.container}>
    <p className={styles.prefectureText}>{prefecture}</p>
    <div className={styles.cityRow}>
      {cities.map(city => (
        <Link
          key={city}
          to={`/${prefecture}/${city}`}
          className={styles.cityLinkText}
        >
          {city}
        </Link>
      ))}
    </div>
  </div>
)
