import { LatLng } from "../../__generated__/proto/google/type/latlng"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { SearchOrderType } from "../../types"
import { UseCase } from "./use-case"
import { GetDistanceUseCase, GetParkingCurrentFeeUseCase } from "."

interface Props {
  parkings: Parking[]
  type: keyof typeof SearchOrderType
  mapCenter?: LatLng
}

export class SortParkingsUseCase implements UseCase<Props, Parking[]> {
  constructor(
    readonly getDistanceUseCase: GetDistanceUseCase,
    readonly getCurrentFeeUseCase: GetParkingCurrentFeeUseCase
  ) {}

  call({ parkings, type, mapCenter }: Props): Parking[] {
    const now = new Date()
    const newParkings = [...parkings]
    return newParkings.sort((a, b) => {
      switch (type) {
        case "DISTANCE":
          if (mapCenter && a.latLng && b.latLng) {
            const aDistance = this.getDistanceUseCase.call({
              from: mapCenter,
              to: a.latLng,
              units: "meters",
            })
            const bDistance = this.getDistanceUseCase.call({
              from: mapCenter,
              to: b.latLng,
              units: "meters",
            })
            return aDistance - bDistance
          }
          return 0
        case "TOTAL_SPACE_COUNT":
          const aTotalSpaceCount = a.availability?.totalSpaceCount
          const bTotalSpaceCount = b.availability?.totalSpaceCount
          if (aTotalSpaceCount == undefined) {
            return 1
          } else if (bTotalSpaceCount == undefined) {
            return -1
          } else {
            return bTotalSpaceCount - aTotalSpaceCount
          }
        case "CURRENT_FEE":
          const aCurrentFee = this.getCurrentFeeUseCase.call({
            parking: a,
            date: now,
          })
          const aUnitDuration = aCurrentFee?.unitDuration
          const aFeePerUnitDuration = aCurrentFee?.feePerUnitDuration?.units
          const bCurrentFee = this.getCurrentFeeUseCase.call({
            parking: b,
            date: now,
          })
          const bUnitDuration = bCurrentFee?.unitDuration
          const bFeePerUnitDuration = bCurrentFee?.feePerUnitDuration?.units
          if (
            aUnitDuration === undefined ||
            aFeePerUnitDuration === undefined
          ) {
            return 1
          } else if (
            bUnitDuration === undefined ||
            bFeePerUnitDuration === undefined
          ) {
            return -1
          } else {
            // NOTE: `unitDuration: Duration` is replaced with `unitDuration: string` formatted as "{seconds}s"
            return (
              aFeePerUnitDuration / Number(aUnitDuration.replace("s", "")) -
              bFeePerUnitDuration / Number(bUnitDuration.replace("s", ""))
            )
          }
        case "CURRENT_MAX_FEE":
          const aMaxFee = this.getCurrentFeeUseCase.call({
            parking: a,
            date: now,
          })?.maxFee?.units
          const bMaxFee = this.getCurrentFeeUseCase.call({
            parking: b,
            date: now,
          })?.maxFee?.units
          if (aMaxFee === undefined) {
            return 1
          } else if (bMaxFee === undefined) {
            return -1
          } else {
            return aMaxFee - bMaxFee
          }
      }
    })
  }
}
