import { getDefaultStore } from "jotai"
import {
  ReserveRequest,
  ReserveResponse,
} from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import { authAtom } from "../../atoms"
import { AuthError, ReservationServiceType } from "../services"
import { UseCase } from "./use-case"

export class ReserveUseCase
  implements UseCase<ReserveRequest, Promise<ReserveResponse>>
{
  constructor(readonly reservationService: ReservationServiceType) {}

  async call(request: ReserveRequest): Promise<ReserveResponse> {
    const token = getDefaultStore().get(authAtom)
    if (!token) {
      throw new AuthError()
    }

    const result = await this.reservationService.reserve(token, request)
    if (result.isFailure()) {
      console.error(result.value)
      return {}
    }
    return result.value
  }
}
