import { GeocodeResult } from "../../__generated__/proto/itech/motorist/pksha/v1/geocode"
import { GeocodeServiceType } from "../services"
import { UseCase } from "./use-case"

interface Props {
  query: string
}

export class GeocodeUseCase
  implements UseCase<Props, Promise<GeocodeResult[]>>
{
  constructor(readonly geocodeService: GeocodeServiceType) {}

  async call(props: Props): Promise<GeocodeResult[]> {
    const result = await this.geocodeService.geocode(props.query)
    if (result.isFailure()) {
      console.error(result.value)
      return []
    }
    return result.value.results
  }
}
