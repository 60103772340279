// extracted by mini-css-extract-plugin
export var wrapper = "search-result-list-item-module--wrapper--14L2F";
export var container = "search-result-list-item-module--container--2ZEgx";
export var parkingNameLink = "search-result-list-item-module--parkingNameLink--37IzI";
export var infoTable = "search-result-list-item-module--infoTable--3KdsP";
export var serviceIcons = "search-result-list-item-module--serviceIcons--10Wou";
export var invalidStatusItem = "search-result-list-item-module--invalidStatusItem--2nd1V";
export var statusItemText = "search-result-list-item-module--statusItemText--2Z6Rb";
export var emptyStatusItem = "search-result-list-item-module--emptyStatusItem--Ws7KG";
export var crowdedStatusItem = "search-result-list-item-module--crowdedStatusItem--1Xus7";
export var fullStatusItem = "search-result-list-item-module--fullStatusItem--23FNy";
export var bottomBorder = "search-result-list-item-module--bottomBorder---bTgL";