import React, { ReactElement } from "react"
import * as styles from "./parking-availability-row.module.scss"

const availavilityItem = (
  statusClassName: string,
  text: string,
  shortText: string
): ReactElement => {
  return (
    <div className={styles.availabilityItem}>
      <div className={statusClassName}>
        <p className={styles.statusItemText}>{shortText}</p>
      </div>
      <p className={styles.availabilityText}>{text}</p>
    </div>
  )
}

export const ParkingAvailavilityRow = (): ReactElement => (
  <div className={styles.row}>
    {availavilityItem(styles.fullStatusItem, "満車", "満")}
    {availavilityItem(styles.emptyStatusItem, "空車", "空")}
    {availavilityItem(styles.crowdedStatusItem, "混雑", "混")}
    {availavilityItem(styles.invalidStatusItem, "不明", "不")}
    <div style={{ flex: 1 }} />
    <p className={styles.info}>※一部駐車場は表示情報が異なる場合がございます</p>
  </div>
)
