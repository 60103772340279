import React, { ReactElement } from "react"
import {
  Parking,
  ParkingAvailability_Summary,
} from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { FeeUnitExtension } from "../../domain/parking-extensions"
import { GetParkingCurrentFeeUseCase } from "../../domain/use-cases"
import * as styles from "./parking-pin.module.scss"

interface Props {
  parking: Parking
  isSelected: boolean
  onSelect: (parking: Parking) => void
}

export const ParkingPin = React.memo((props: Props): ReactElement => {
  // MARK: - UseCases

  const getCurrentFeeUseCase = new GetParkingCurrentFeeUseCase()

  // MARK: - Components

  const statusItem = (
    availability?: ParkingAvailability_Summary
  ): ReactElement => {
    let className: string
    let title: string
    switch (availability) {
      case undefined:
      case ParkingAvailability_Summary.SUMMARY_UNOBTAINABLE:
      case ParkingAvailability_Summary.SUMMARY_INVALID:
        className = styles.invalidStatusItem
        title = "不"
        break
      case ParkingAvailability_Summary.SUMMARY_EMPTY:
        className = styles.emptyStatusItem
        title = "空"
        break
      case ParkingAvailability_Summary.SUMMARY_CROWDED:
        className = styles.crowdedStatusItem
        title = "混"
        break
      case ParkingAvailability_Summary.SUMMARY_FULL:
        className = styles.fullStatusItem
        title = "満"
        break
    }
    return (
      <div className={className}>
        <p className={styles.statusItemText}>{title}</p>
      </div>
    )
  }

  // MARK: - Render

  return (
    <a
      className={props.isSelected ? styles.selectedPin : styles.unselectedPin}
      onClick={() => props.onSelect(props.parking)}
    >
      {statusItem(props.parking.availability?.summary)}
      <div className={styles.currentFee}>
        <p>{currentFee(props.parking)}</p>
      </div>
    </a>
  )

  // MARK: - Methods

  function currentFee(parking: Parking): string {
    const feeUnit = getCurrentFeeUseCase.call({
      parking: parking,
      date: new Date(),
    })
    if (!feeUnit) {
      return "料金不明"
    }
    const yen = FeeUnitExtension.wrappedFeePerUnitDuration(feeUnit)
    const unitMinutes = FeeUnitExtension.wrappedUnitDurationInMinutes(feeUnit)
    if (!yen || !unitMinutes) {
      return "料金不明"
    }

    return `${yen}円/${unitMinutes}分`
  }
})
