import * as holiday_jp from "@holiday-jp/holiday_jp"
import {
  FeeTable,
  Parking,
  SubMaxFee,
} from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { FeeTableExtension } from "../parking-extensions"
import { UseCase } from "./use-case"

interface Props {
  parking: Parking
  date: Date
}

export class GetParkingCurrentSubMaxFeeUseCase
  implements UseCase<Props, SubMaxFee | null>
{
  call({ parking, date }: Props): SubMaxFee | null {
    const feeTable = parking.feeTable
    if (!feeTable) {
      return null
    }

    const subMaxFee = this.subMaxFeeOfToday(feeTable, date)
    if (!subMaxFee) {
      return {}
    }
    return subMaxFee
  }

  subMaxFeeOfToday(ft: FeeTable, date: Date): SubMaxFee | undefined {
    const isHoliday = holiday_jp.isHoliday(date)
    if (isHoliday) {
      return FeeTableExtension.holidaySubMaxFee(ft) // Holiday
    }

    switch (date.getDay()) {
      case 0: // Sunday
        return FeeTableExtension.sundaySubMaxFee(ft)
      case 1: // Monday
      case 2: // Tuesday
      case 3: // Wednesday
      case 4: // Thursday
      case 5: // Friday
        return FeeTableExtension.weekdaySubMaxFee(ft)
      case 6: // Saturday
        return FeeTableExtension.saturdaySubMaxFee(ft)

      default:
        return undefined // unexpected error
    }
  }
}
