import { getDefaultStore } from "jotai"
import {
  CalculateCancellationFeeRequest,
  CalculateCancellationFeeResponse,
} from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import { authAtom } from "../../atoms"
import { ReservationServiceType, AuthError } from "../services"
import { UseCase } from "./use-case"

export class CalculateCancellationFeeUseCase
  implements
    UseCase<
      CalculateCancellationFeeRequest,
      Promise<CalculateCancellationFeeResponse>
    >
{
  constructor(readonly ReservationService: ReservationServiceType) {}

  async call(
    request: CalculateCancellationFeeRequest
  ): Promise<CalculateCancellationFeeResponse> {
    const token = getDefaultStore().get(authAtom)
    if (!token) {
      throw new AuthError()
    }

    const result = await this.ReservationService.calculateCancellationFee(
      token,
      request
    )
    if (result.isFailure()) {
      console.error(result.value)
      return {}
    }
    return result.value
  }
}
