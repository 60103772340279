import { User } from "../models"
import { AuthServiceType } from "../services"
import { UseCase } from "./use-case"

interface Props {
  username: string
  password: string
}

export class LoginUseCase implements UseCase<Props, Promise<User | null>> {
  constructor(readonly authService: AuthServiceType) {}

  async call({ username, password }: Props): Promise<User | null> {
    const result = await this.authService.login(username, password)
    if (result.isFailure()) {
      console.error(result.value)
      return null
    }
    return result.value
  }
}
