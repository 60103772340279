import axios from "axios"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import {
  GetParkingRequest,
  GetParkingResponse,
  ListParkingsRequest,
  ListParkingsResponse,
} from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import * as config from "../../config"
import { sendRequest } from "./network"
import { Failure, Result, Success } from "./result"

// MARK: - Interfaces
export interface ParkingServiceType {
  getParking(
    request: GetParkingRequest
  ): Promise<Result<Parking, GetParkingError>>
  listParkings(
    request: ListParkingsRequest
  ): Promise<Result<ListParkingsResponse, ListParkingsError>>
}

// MARK: - Implements
export class ParkingService implements ParkingServiceType {
  async getParking(
    request: GetParkingRequest
  ): Promise<Result<Parking, GetParkingError>> {
    try {
      const response = await axios.post<GetParkingResponse>(
        `${config.PKSHA_HTTP_API_URL}/parking`,
        {
          headers: { "Content-Type": "application/json" },
          name: request.name,
        }
      )

      if (response.data.parking) {
        return new Success(response.data.parking)
      } else {
        return new Failure(new GetParkingError("Parking not found"))
      }
    } catch (error) {
      console.error(error)
      return new Failure(new GetParkingError())
    }
  }

  async listParkings(
    request: ListParkingsRequest
  ): Promise<Result<ListParkingsResponse, ListParkingsError>> {
    return sendRequest<ListParkingsResponse>(
      "post",
      `${config.PKSHA_HTTP_API_URL}/parkings`,
      {
        page_size: request.pageSize,
        page_token: request.pageToken,
        bounding_box: request.boundingBox,
      },
      { "Content-Type": "application/json" }
    )
  }
}

// MARK: - Errors

export class GetParkingError extends Error {}
export class ListParkingsError extends Error {}
