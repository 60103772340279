import { navigate } from "gatsby-link"
import React, { ReactElement, useState } from "react"
import * as styles from "./search-input-bar.module.scss"

export const SearchInputBar = (): ReactElement => {
  const [input, setInput] = useState("")

  return (
    <form
      className={styles.searchBar}
      onSubmit={e => {
        e.preventDefault()
        navigate(`/search?q=${input}`)
      }}
    >
      <img
        src="/search.webp"
        className={styles.searchIcon}
        alt="serach"
        width={18}
        height={18}
      />
      <input
        type="text"
        placeholder="駅名/地名/施設/住所などで検索"
        enterKeyHint="search"
        className={styles.searchField}
        onChange={e => setInput(e.target.value)}
      />
    </form>
  )
}
