import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { Button } from "../common/button"
import * as styles from "./parking-reservation-form.module.scss"

interface Props {
  onSubmit: (
    licenseNumber: string,
    spaceNumber: string,
    requireHandicappedSpace: boolean
  ) => void
}

export const ParkingReservationForm = React.memo(
  function ParkingReservationInfoTable(props: Props): ReactElement {
    const licenseNumberRef = useRef<TextInputHandle>()
    const spaceNumberRef = useRef<TextInputHandle>()
    const [licenseNumber, setLicenseNumber] = useState("")
    const [spaceNumber, setSpaceNumber] = useState("")
    const [requireHandicappedSpace, setRequireHandicappedSpace] =
      useState(false)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      // check if the license number and space number are valid before submitting
      if (
        licenseNumberRef.current == null ||
        spaceNumberRef.current == null ||
        !licenseNumberRef.current.validate(licenseNumber) ||
        !spaceNumberRef.current.validate(spaceNumber)
      ) {
        return false
      }

      const normalizedLicenseNumber =
        licenseNumberRef.current.normalize(licenseNumber)
      const normalizedSpaceNumber =
        spaceNumberRef.current.normalize(spaceNumber)
      setLicenseNumber(normalizedLicenseNumber)
      setSpaceNumber(normalizedSpaceNumber)

      return props.onSubmit(
        normalizedLicenseNumber,
        normalizedSpaceNumber,
        requireHandicappedSpace
      )
    }

    return (
      <form className={styles.container} onSubmit={handleSubmit}>
        <div className={styles.flexbox}>
          <LicenseNumberInput
            ref={licenseNumberRef}
            value={licenseNumber}
            onChange={setLicenseNumber}
          />
          <SpaceNumberInput
            ref={spaceNumberRef}
            value={spaceNumber}
            onChange={setSpaceNumber}
          />
        </div>
        <HandicappedSpaceCheckbox
          checked={requireHandicappedSpace}
          onChange={e => setRequireHandicappedSpace(e.target.checked)}
        />
        <p className={styles.importantNoticeText}>
          ※ご予約を開始された後、予約完了画面を必ずご確認ください。
        </p>
        <div className={styles.footer}>
          <Button layout="fill" type="submit">
            予約する
          </Button>
        </div>
      </form>
    )
  }
)

interface TextInputHandle {
  validate: (number: string) => boolean
  normalize: (number: string) => string
}

interface TextInputProps {
  value: string
  onChange: (number: string) => void
}

const LicenseNumberInput = forwardRef(
  ({ value, onChange }: TextInputProps, ref): ReactElement => {
    const [error, setError] = useState<string>()

    const validate = (number: string): boolean => {
      let errorMessage = ""
      if (number === "") {
        errorMessage = "車両ナンバーを入力してください。"
      } else if (!/^\d+$/.test(number)) {
        errorMessage = "車両ナンバーは整数でなければなりません。"
      }
      setError(errorMessage)
      return errorMessage === ""
    }

    const normalize = (number: string): string => {
      return number.padStart(4, "0")
    }

    const handleChange = (text: string) => {
      validate(text)
      onChange(text)
    }

    useImperativeHandle(ref, () => ({
      validate,
      normalize,
    }))

    return (
      <div className={styles.textInput}>
        <div className={styles.infoKeySection}>車両ナンバー（必須）</div>
        <div className={styles.infoValueSection}>
          <input
            type="text"
            className={styles.licenseNumberInput}
            placeholder="例）1234"
            value={value}
            maxLength={4}
            onChange={e => handleChange(e.target.value)}
          />
          {error && <div className={styles.error}>{error}</div>}
          <div className={styles.notice}>
            駐車車両とご予約車の確認のために、4桁の車両ナンバー(数字のみ)の入力をお願いします。
          </div>
        </div>
      </div>
    )
  }
)

const SpaceNumberInput = forwardRef(
  ({ value, onChange }: TextInputProps, ref): ReactElement => {
    const [error, setError] = useState<string>()

    const validate = (number: string): boolean => {
      let errorMessage = ""
      if (number !== "" && !/^\d+$/.test(number)) {
        errorMessage = "車室番号は整数でなければなりません。"
      }
      setError(errorMessage)
      return errorMessage === ""
    }

    const normalize = (number: string): string => {
      return number === "" ? "" : number.padStart(3, "0")
    }

    const handleChange = (text: string) => {
      validate(text)
      onChange(text)
    }

    useImperativeHandle(ref, () => ({
      validate,
      normalize,
    }))

    return (
      <div className={styles.textInput}>
        <div className={styles.infoKeySection}>車室番号（任意）</div>
        <div className={styles.infoValueSection}>
          <input
            type="text"
            className={styles.spaceNumberInput}
            placeholder="例）3"
            value={value}
            maxLength={3}
            onChange={e => handleChange(e.target.value)}
          />
          {error && <div className={styles.error}>{error}</div>}
          <div className={styles.notice}>
            車室を指定された場合、他の車室のご利用はできません。
          </div>
        </div>
      </div>
    )
  }
)

const HandicappedSpaceCheckbox = ({
  checked,
  onChange,
}: {
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}): ReactElement => {
  return (
    <div className={styles.handicappedSpaceCheckbox}>
      <div className={styles.infoKeySection}></div>
      <div className={styles.infoValueSection}>
        <div className={styles.checkboxRow}>
          <input
            type="checkbox"
            id="requireHandicappedSpace"
            checked={checked}
            onChange={onChange}
          />
          <label htmlFor="requireHandicappedSpace">
            車椅子対応車室を優先して予約する
          </label>
        </div>
      </div>
    </div>
  )
}
