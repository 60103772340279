import {
  ReserveResponse,
  CalculateCancellationFeeResponse,
  CancelReservationResponse,
  AcceptEntryResponse,
  RejectEntryResponse,
  ReserveRequest,
  CalculateCancellationFeeRequest,
  CancelReservationRequest,
  AcceptEntryRequest,
  RejectEntryRequest,
} from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import * as config from "../../config"
import { sendRequest } from "./network"
import { Result } from "./result"

// MARK: - Interfaces
export interface ReservationServiceType {
  reserve(
    token: string,
    request: ReserveRequest
  ): Promise<Result<ReserveResponse, ReserveError>>
  calculateCancellationFee(
    token: string,
    request: CalculateCancellationFeeRequest
  ): Promise<
    Result<CalculateCancellationFeeResponse, CalculateCancellationFeeError>
  >
  cancelReservation(
    token: string,
    request: CancelReservationRequest
  ): Promise<Result<CancelReservationResponse, CancelReservationError>>
  acceptEntry(
    token: string,
    request: AcceptEntryRequest
  ): Promise<Result<AcceptEntryResponse, AcceptEntryError>>
  rejectEntry(
    token: string,
    request: RejectEntryRequest
  ): Promise<Result<RejectEntryResponse, RejectEntryError>>
}

// MARK: - Implements
export class ReservationService implements ReservationServiceType {
  async reserve(
    token: string,
    request: ReserveRequest
  ): Promise<Result<ReserveResponse, ReserveError>> {
    return sendRequest<ReserveResponse>(
      "post",
      `${config.PKSHA_HTTP_API_URL}/reserve`,
      {
        parking_space_name: request.parkingSpaceName,
        request_id: request.requestId,
        license_number: request.licenseNumber,
        required_handicapped_space: request.requireHandicappedSpace,
      },
      {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      }
    )
  }

  async calculateCancellationFee(
    token: string,
    request: CalculateCancellationFeeRequest
  ): Promise<
    Result<CalculateCancellationFeeResponse, CalculateCancellationFeeError>
  > {
    return sendRequest<CalculateCancellationFeeResponse>(
      "post",
      `${config.PKSHA_HTTP_API_URL}/${request.transactionName}:calculate-cancellation-fee`,
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      }
    )
  }

  async cancelReservation(
    token: string,
    request: CancelReservationRequest
  ): Promise<Result<CancelReservationResponse, CancelReservationError>> {
    return sendRequest<CancelReservationResponse>(
      "post",
      `${config.PKSHA_HTTP_API_URL}/${request.transactionName}:cancel-reservation`,
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      }
    )
  }

  async acceptEntry(
    token: string,
    request: AcceptEntryRequest
  ): Promise<Result<AcceptEntryResponse, AcceptEntryError>> {
    return sendRequest<AcceptEntryResponse>(
      "post",
      `${config.PKSHA_HTTP_API_URL}/${request.transactionName}:accept-entry`,
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      }
    )
  }

  async rejectEntry(
    token: string,
    request: RejectEntryRequest
  ): Promise<Result<RejectEntryResponse, RejectEntryError>> {
    return sendRequest<RejectEntryResponse>(
      "post",
      `${config.PKSHA_HTTP_API_URL}/${request.transactionName}:reject-entry`,
      {},
      {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      }
    )
  }
}

// MARK: - Errors

export class ReserveError extends Error {}
export class CalculateCancellationFeeError extends Error {}
export class CancelReservationError extends Error {}
export class AcceptEntryError extends Error {}
export class RejectEntryError extends Error {}
