import {
  FeeUnit,
  FeeTable,
  HourlyMaxFee,
  SubMaxFee,
} from "../__generated__/proto/itech/motorist/pksha/v1/parking"

// MARK: - extensions for FeeTable

export class FeeTableExtension {
  static weekdaySubMaxFee(feeTable: FeeTable): SubMaxFee | undefined {
    return feeTable.weekdayMaxFee
  }
  static saturdaySubMaxFee(feeTable: FeeTable): SubMaxFee | undefined {
    return feeTable.saturdayMaxFee
  }
  static sundaySubMaxFee(feeTable: FeeTable): SubMaxFee | undefined {
    return feeTable.sundayMaxFee
  }
  static holidaySubMaxFee(feeTable: FeeTable): SubMaxFee | undefined {
    return feeTable.holidayMaxFee
  }
}

// MARK: - extensions for FeeUnit

export class FeeUnitExtension {
  static wrappedUnitDurationInMinutes(feeUnit: FeeUnit): number | undefined {
    if (!feeUnit.unitDuration) {
      return undefined
    }

    // NOTE: `unitDuration: Duration` is replaced with `unitDuration: string` formatted as "{seconds}s"
    const duration = Number(feeUnit.unitDuration.replace("s", ""))
    return Math.floor(Math.floor(duration) / 60)
  }

  static includes(feeUnit: FeeUnit, time: Date): boolean {
    const startTime = feeUnit.startTime
    const endTime = feeUnit.endTime
    if (!startTime || !endTime) {
      return false
    }

    const st = startTime.hours * 60 + startTime.minutes
    const et = endTime.hours * 60 + endTime.minutes
    const t = time.getHours() * 60 + time.getMinutes()
    return st <= t && t < et
  }

  static isValidFeeUnitMaxFee(feeUnit: FeeUnit): boolean {
    if (!feeUnit.maxFee) {
      return false
    }

    return Math.floor(feeUnit.maxFee.units) > 0
  }

  static wrappedFeePerUnitDuration(feeUnit: FeeUnit): string | undefined {
    if (!feeUnit.feePerUnitDuration) {
      return undefined
    }

    return feeUnit.feePerUnitDuration.units.toLocaleString()
  }

  static wrappedMaxFee(feeUnit: FeeUnit): string | undefined {
    if (!feeUnit.maxFee) {
      return undefined
    }

    return feeUnit.maxFee.units.toLocaleString()
  }
}

// MARK: - extensions for HourlyMaxFee

export class HourlyMaxFeeExtension {
  static wrappedHourDurationInHours(
    hourlyMaxFee: HourlyMaxFee
  ): number | undefined {
    if (!hourlyMaxFee.hourDuration) {
      return undefined
    }

    // NOTE: `unitDuration: Duration` is replaced with `unitDuration: string` formatted as "{seconds}s"
    const duration = Number(hourlyMaxFee.hourDuration.replace("s", ""))
    return Math.floor(Math.floor(duration) / 3600)
  }
  static wrappedFeePerHour(hourlyMaxFee: HourlyMaxFee): string | undefined {
    if (!hourlyMaxFee.feePerHour) {
      return undefined
    }

    return hourlyMaxFee.feePerHour.units.toLocaleString()
  }
}

// MARK: - extensions for SubMaxFee

export class SubMaxFeeExtension {
  static wrappedDaily(subMaxFee: SubMaxFee): number | undefined {
    if (!subMaxFee.daily) {
      return undefined
    }

    return Math.floor(subMaxFee.daily.units)
  }
}
