import { ReactNodeLike } from "prop-types"
import React, { ReactElement } from "react"

import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { Footer } from "./footer"
import { Header } from "./header"
import * as styles from "./layout.module.scss"

interface LayoutProps {
  children: ReactNodeLike
  currentParking?: Parking
}

export const Layout = ({
  children,
  currentParking,
}: LayoutProps): ReactElement => {
  return (
    <div className={styles.body}>
      <Header currentParking={currentParking} />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  )
}
