import axios from "axios"
import Papa from "papaparse"
import { Address } from "../models"
import { Failure, Result, Success } from "./result"

type Json = { [key: string]: string }

// MARK: - Interfaces

export interface AddressServiceType {
  getAddresses(): Promise<Result<Address[], GetAddressDataError>>
}

// MARK: - Implements

export class AddressService implements AddressServiceType {
  async getAddresses(): Promise<Result<Address[], GetAddressDataError>> {
    try {
      const response = await axios.get<string>(
        "https://raw.githubusercontent.com/geolonia/japanese-addresses/master/data/latest.csv"
      )
      const addresses: Address[] = Papa.parse<Json>(response.data, {
        header: true,
        skipEmptyLines: true,
      })
        .data.filter((json: Json) => json["小字・通称名"].length === 0)
        .map((json: Json) => {
          return Address.fromJson(json)
        })
      return new Success(addresses)
    } catch (error) {
      console.error(error)
      return new Failure(new GetAddressDataError())
    }
  }
}

// MARK: - Errors

export class GetAddressDataError extends Error {}
