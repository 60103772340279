import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import { ParkingServiceType } from "../services"
import { UseCase } from "./use-case"

interface Props {
  name: string
}

export class GetParkingUseCase
  implements UseCase<Props, Promise<Parking | null>>
{
  constructor(readonly parkingService: ParkingServiceType) {}

  async call(props: Props): Promise<Parking | null> {
    const result = await this.parkingService.getParking({ name: props.name })
    if (result.isFailure()) {
      console.error(result.value)
      return null
    }
    return result.value
  }
}
