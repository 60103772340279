import React, { ReactElement } from "react"
import * as config from "../../config"
import * as styles from "./area-breadcrumbs-row.module.scss"

interface Props {
  prefecture?: string
  city?: string
  town?: string
  parkingName?: string
  onTapAreaSelection?: () => void
}

const rightIcon = (
  <img
    src="/right.webp"
    className={styles.rightIcon}
    alt="right-arrow"
    width={18}
    height={18}
  />
)

export const AreaBreadcrumbsRow = ({
  prefecture,
  city,
  town,
  parkingName,
  onTapAreaSelection,
}: Props): ReactElement => (
  <div className={styles.container}>
    <ol
      className={styles.breadcrumbs}
      vocab="https://schema.org/"
      typeof="BreadcrumbList"
    >
      {prefecture && (
        <li property="itemListElement" typeof="ListItem">
          <a
            className={styles.link}
            property="item"
            typeof="WebPage"
            href={`${config.SITE_URL}/${prefecture}`}
          >
            <span property="name">{prefecture}</span>
          </a>
          <meta property="position" content="1" />
        </li>
      )}
      {city && (
        <>
          {rightIcon}
          <li property="itemListElement" typeof="ListItem">
            {town || parkingName ? (
              <a
                className={styles.link}
                property="item"
                typeof="WebPage"
                href={`${config.SITE_URL}/${prefecture}/${city}`}
              >
                <span property="name">{city}</span>
              </a>
            ) : (
              <span property="name">{city}</span>
            )}
            <meta property="position" content="2" />
          </li>
        </>
      )}
      {town && (
        <>
          {rightIcon}
          <li property="itemListElement" typeof="ListItem">
            {parkingName ? (
              <a
                className={styles.link}
                property="item"
                typeof="WebPage"
                href={`${config.SITE_URL}/${prefecture}/${city}/${town}`}
              >
                <span property="name">{town}</span>
              </a>
            ) : (
              <span property="name">{town}</span>
            )}
            <meta property="position" content="3" />
          </li>
        </>
      )}
      {parkingName && (
        <>
          {rightIcon}
          <li property="itemListElement" typeof="ListItem">
            <span property="name">{parkingName}</span>
            <meta property="position" content="4" />
          </li>
        </>
      )}
    </ol>
    <span style={{ flex: 1 }} />
    {onTapAreaSelection && (
      <a className={styles.areaSelectionButton} onClick={onTapAreaSelection}>
        地域選択で探す
      </a>
    )}
  </div>
)
