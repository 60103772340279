import React, { ReactElement } from "react"
import { Parking } from "../../../__generated__/proto/itech/motorist/pksha/v1/parking"
import * as styles from "./parking-detail-info-row.module.scss"

export const AddressInfoRow = ({
  parking,
  zipcode,
}: {
  parking: Parking
  zipcode: string | null
}): ReactElement => {
  const zipcodeText = zipcode !== null ? `〒${zipcode}` : ""
  return (
    <div className={styles.infoRow}>
      <div className={styles.infoKeySection}>住所</div>
      <div className={styles.infoValueSection}>
        <p className={styles.addressText}>
          {zipcodeText} {parking.fullAddress}
        </p>
        <a
          className={styles.openGoogleMapButton}
          href={`https://maps.google.com/maps?q=${parking.fullAddress}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Google mapで開く
        </a>
        <a
          className={styles.copyAddressButton}
          onClick={e => {
            e.preventDefault()
            navigator.clipboard.writeText(parking.fullAddress)
          }}
        >
          住所をコピー
        </a>
      </div>
    </div>
  )
}
