import {
  ListTransactionsRequest,
  ListTransactionsResponse,
} from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import * as config from "../../config"
import { sendRequest } from "./network"
import { Result } from "./result"

// MARK: - Interfaces
export interface TransactionServiceType {
  listTransactions(
    token: string,
    request: ListTransactionsRequest
  ): Promise<Result<ListTransactionsResponse, ListTransactionsError>>
}

// MARK: - Implements
export class TransactionService implements TransactionServiceType {
  async listTransactions(
    token: string,
    request: ListTransactionsRequest
  ): Promise<Result<ListTransactionsResponse, ListTransactionsError>> {
    return sendRequest<ListTransactionsResponse>(
      "get",
      `${config.PKSHA_HTTP_API_URL}/transactions`,
      {
        page_size: request.pageSize,
        page_token: request.pageToken,
        parking_name: request.parkingName,
        parking_raw_id: request.parkingRawId,
      },
      {
        "Content-Type": "application/json",
        Authorization: `Basic ${token}`,
      }
    )
  }
}

// MARK: - Errors

export class ListTransactionsError extends Error {}
