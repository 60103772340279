import React, { ReactElement } from "react"
import { LatLng } from "../../__generated__/proto/google/type/latlng"
import { Parking } from "../../__generated__/proto/itech/motorist/pksha/v1/parking"
import * as config from "../../config"
import { SearchResultListItem } from "./search-result-list-item"
import * as styles from "./search-result-list.module.scss"

interface Props {
  parkings: Parking[]
  currentPage?: number
  mapCenter: LatLng | null
  onClickItem: (parking: Parking) => void
  onClickParkingName: (parking: Parking) => void
}

const emptyView = (): ReactElement => (
  <div className={styles.emptyView}>
    <p className={styles.emptyText}>
      駐車場がありません。
      <br />
      地名/住所で再検索するかmapの範囲を変更してください。
    </p>
  </div>
)

export const SearchResultList = React.memo((props: Props): ReactElement => {
  if (props.parkings.length === 0) {
    return emptyView()
  }

  let displayingParkings = props.parkings

  if (props.currentPage) {
    displayingParkings = displayingParkings.slice(
      config.PARKINGS_COUNT_PER_PAGE * (props.currentPage - 1),
      config.PARKINGS_COUNT_PER_PAGE * props.currentPage
    )
  }

  return (
    <div id="searchResultList" className={styles.list}>
      {displayingParkings.map(parking => (
        <div key={parking.name} id={parking.name} className={styles.listItem}>
          <SearchResultListItem
            parking={parking}
            mapCenter={props.mapCenter}
            onClick={props.onClickItem}
            onClickName={props.onClickParkingName}
          />
        </div>
      ))}
    </div>
  )
})
