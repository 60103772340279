import axios from "axios"
import { Failure, Result, Success } from "./result"

export async function sendRequest<T>(
  method: "get" | "post",
  url: string,
  data: Record<string, unknown>,
  headers: Record<string, string>
): Promise<Result<T, Error>> {
  try {
    const response =
      method === "get"
        ? await axios.get<T>(url, { headers, params: data })
        : await axios.post<T>(url, data, { headers })

    return new Success(response.data)
  } catch (error) {
    return new Failure(error as Error)
  }
}
