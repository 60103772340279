/* eslint-disable */
import Long from "long"
import _m0 from "protobufjs/minimal"
import { DateMessage } from "../../../../google/type/date"

export const protobufPackage = "itech.motorist.pksha.v1"

/** A representation of the parking business owner. */
export interface ParkingBusinessOwner {
  /**
   * Unique identifier for the receipt issuing, representing its registered
   * number.
   */
  registrationNumber: string
  /** Name of parking bussiness owner. */
  displayName: string
  /**
   * active date is the date the invoice registration number is displayed on the
   * receipt.
   */
  activeDate?: DateMessage
}

const baseParkingBusinessOwner: object = {
  registrationNumber: "",
  displayName: "",
}

export const ParkingBusinessOwner = {
  encode(
    message: ParkingBusinessOwner,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.registrationNumber !== "") {
      writer.uint32(10).string(message.registrationNumber)
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName)
    }
    if (message.activeDate !== undefined) {
      DateMessage.encode(message.activeDate, writer.uint32(26).fork()).ldelim()
    }
    return writer
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number
  ): ParkingBusinessOwner {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseParkingBusinessOwner } as ParkingBusinessOwner
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.registrationNumber = reader.string()
          break
        case 2:
          message.displayName = reader.string()
          break
        case 3:
          message.activeDate = DateMessage.decode(reader, reader.uint32())
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): ParkingBusinessOwner {
    const message = { ...baseParkingBusinessOwner } as ParkingBusinessOwner
    if (
      object.registrationNumber !== undefined &&
      object.registrationNumber !== null
    ) {
      message.registrationNumber = String(object.registrationNumber)
    } else {
      message.registrationNumber = ""
    }
    if (object.displayName !== undefined && object.displayName !== null) {
      message.displayName = String(object.displayName)
    } else {
      message.displayName = ""
    }
    if (object.activeDate !== undefined && object.activeDate !== null) {
      message.activeDate = DateMessage.fromJSON(object.activeDate)
    } else {
      message.activeDate = undefined
    }
    return message
  },

  toJSON(message: ParkingBusinessOwner): unknown {
    const obj: any = {}
    message.registrationNumber !== undefined &&
      (obj.registrationNumber = message.registrationNumber)
    message.displayName !== undefined && (obj.displayName = message.displayName)
    message.activeDate !== undefined &&
      (obj.activeDate = message.activeDate
        ? DateMessage.toJSON(message.activeDate)
        : undefined)
    return obj
  },

  fromPartial(object: DeepPartial<ParkingBusinessOwner>): ParkingBusinessOwner {
    const message = { ...baseParkingBusinessOwner } as ParkingBusinessOwner
    if (
      object.registrationNumber !== undefined &&
      object.registrationNumber !== null
    ) {
      message.registrationNumber = object.registrationNumber
    } else {
      message.registrationNumber = ""
    }
    if (object.displayName !== undefined && object.displayName !== null) {
      message.displayName = object.displayName
    } else {
      message.displayName = ""
    }
    if (object.activeDate !== undefined && object.activeDate !== null) {
      message.activeDate = DateMessage.fromPartial(object.activeDate)
    } else {
      message.activeDate = undefined
    }
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any
  _m0.configure()
}
