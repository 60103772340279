import React, { ReactElement } from "react"
import { SearchOrderType } from "../../types"
import * as styles from "./search-result-sort-menu.module.scss"

interface Props {
  currentOrderType: keyof typeof SearchOrderType
  onSelectOrderType: (orderType: keyof typeof SearchOrderType) => void
}

const MenuItem = React.memo(
  (props: {
    type: keyof typeof SearchOrderType
    isSelected: boolean
    onSelectOrderType: (orderType: keyof typeof SearchOrderType) => void
  }): ReactElement => (
    <a
      className={styles.menuItem}
      onClick={e => {
        e.preventDefault()
        props.onSelectOrderType(props.type)
      }}
    >
      {props.isSelected && (
        <img src="/check.webp" alt="check" width={21} height={21} />
      )}
      <p className={styles.sortText}>{SearchOrderType[props.type]}</p>
    </a>
  )
)
export const SearchResultSortMenu = React.memo((props: Props): ReactElement => {
  return (
    <div className={styles.menu}>
      <MenuItem
        type="DISTANCE"
        isSelected={props.currentOrderType === "DISTANCE"}
        onSelectOrderType={props.onSelectOrderType}
      />
      <MenuItem
        type="TOTAL_SPACE_COUNT"
        isSelected={props.currentOrderType === "TOTAL_SPACE_COUNT"}
        onSelectOrderType={props.onSelectOrderType}
      />
      <MenuItem
        type="CURRENT_FEE"
        isSelected={props.currentOrderType === "CURRENT_FEE"}
        onSelectOrderType={props.onSelectOrderType}
      />
      <MenuItem
        type="CURRENT_MAX_FEE"
        isSelected={props.currentOrderType === "CURRENT_MAX_FEE"}
        onSelectOrderType={props.onSelectOrderType}
      />
    </div>
  )
})
