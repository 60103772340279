import axios from "axios"
import { GeocodeResponse } from "../../__generated__/proto/itech/motorist/pksha/v1/pksha_api"
import * as config from "../../config"
import { Failure, Result, Success } from "./result"

export interface GeocodeServiceType {
  geocode(query: string): Promise<Result<GeocodeResponse, GetGeocodesError>>
}

export class GeocodeService implements GeocodeServiceType {
  async geocode(
    query: string
  ): Promise<Result<GeocodeResponse, GetGeocodesError>> {
    try {
      const response = await axios.post<GeocodeResponse>(
        `${config.PKSHA_HTTP_API_URL}/geocode`,
        {
          headers: { "Content-Type": "application/json" },
          query: query,
        }
      )

      return new Success(response.data)
    } catch (error) {
      console.error(error)
      return new Failure(new GetGeocodesError())
    }
  }
}

// MARK: - Errors

export class GetGeocodesError extends Error {}
