import { LatLng } from "../../__generated__/proto/google/type/latlng"
import { UseCase } from "./use-case"

export class GetCurrentPositionUseCase
  implements UseCase<void, Promise<LatLng | null>>
{
  call(): Promise<LatLng | null> {
    return new Promise(resolve => {
      if (!navigator.geolocation) {
        resolve(null)
      }
      navigator.geolocation.getCurrentPosition(
        position =>
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }),
        () => resolve(null),
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0,
        }
      )
    })
  }
}
