// extracted by mini-css-extract-plugin
export var container = "parking-reservation-form-module--container--1Y-QF";
export var flexbox = "parking-reservation-form-module--flexbox--nExu5";
export var textInput = "parking-reservation-form-module--textInput--TIVPq";
export var infoKeySection = "parking-reservation-form-module--infoKeySection--36Fvx";
export var infoValueSection = "parking-reservation-form-module--infoValueSection--3TjZA";
export var spaceNumberInput = "parking-reservation-form-module--spaceNumberInput--22q_Z";
export var licenseNumberInput = "parking-reservation-form-module--licenseNumberInput--3ttcg";
export var notice = "parking-reservation-form-module--notice--3pxNi";
export var handicappedSpaceCheckbox = "parking-reservation-form-module--handicappedSpaceCheckbox--1Orc6";
export var checkboxRow = "parking-reservation-form-module--checkboxRow--1ekZr";
export var importantNoticeText = "parking-reservation-form-module--importantNoticeText--3Ta4D";
export var footer = "parking-reservation-form-module--footer--2n9eZ";
export var error = "parking-reservation-form-module--error--39BwT";
export var dialogDescription = "parking-reservation-form-module--dialogDescription--ppFKy";
export var reservationTimeTitle = "parking-reservation-form-module--reservationTimeTitle--2D1gO";