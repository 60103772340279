/* eslint-disable */
import Long from "long"
import _m0 from "protobufjs/minimal"

export const protobufPackage = "google.type"

/**
 * An object that represents a latitude/longitude pair. This is expressed as a
 * pair of doubles to represent degrees latitude and degrees longitude. Unless
 * specified otherwise, this must conform to the
 * <a href="http://www.unoosa.org/pdf/icg/2012/template/WGS_84.pdf">WGS84
 * standard</a>. Values must be within normalized ranges.
 */
export interface LatLng {
  /** The latitude in degrees. It must be in the range [-90.0, +90.0]. */
  latitude: number
  /** The longitude in degrees. It must be in the range [-180.0, +180.0]. */
  longitude: number
}

const baseLatLng: object = { latitude: 0, longitude: 0 }

export const LatLng = {
  encode(
    message: LatLng,
    writer: _m0.Writer = _m0.Writer.create()
  ): _m0.Writer {
    if (message.latitude !== 0) {
      writer.uint32(9).double(message.latitude)
    }
    if (message.longitude !== 0) {
      writer.uint32(17).double(message.longitude)
    }
    return writer
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LatLng {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input)
    let end = length === undefined ? reader.len : reader.pos + length
    const message = { ...baseLatLng } as LatLng
    while (reader.pos < end) {
      const tag = reader.uint32()
      switch (tag >>> 3) {
        case 1:
          message.latitude = reader.double()
          break
        case 2:
          message.longitude = reader.double()
          break
        default:
          reader.skipType(tag & 7)
          break
      }
    }
    return message
  },

  fromJSON(object: any): LatLng {
    const message = { ...baseLatLng } as LatLng
    if (object.latitude !== undefined && object.latitude !== null) {
      message.latitude = Number(object.latitude)
    } else {
      message.latitude = 0
    }
    if (object.longitude !== undefined && object.longitude !== null) {
      message.longitude = Number(object.longitude)
    } else {
      message.longitude = 0
    }
    return message
  },

  toJSON(message: LatLng): unknown {
    const obj: any = {}
    message.latitude !== undefined && (obj.latitude = message.latitude)
    message.longitude !== undefined && (obj.longitude = message.longitude)
    return obj
  },

  fromPartial(object: DeepPartial<LatLng>): LatLng {
    const message = { ...baseLatLng } as LatLng
    if (object.latitude !== undefined && object.latitude !== null) {
      message.latitude = object.latitude
    } else {
      message.latitude = 0
    }
    if (object.longitude !== undefined && object.longitude !== null) {
      message.longitude = object.longitude
    } else {
      message.longitude = 0
    }
    return message
  },
}

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any
  _m0.configure()
}
